@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");
/* @import url('https://fonts.cdnfonts.com/css/halcom'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rajdhani:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Amarillo';
  font-style: normal;
  font-weight: normal;
  src: url('../public/assets/fonts/Amarillo.woff') format('woff');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primaryColor: #01B0D0;
  --secondaryColor: #012F38;
  --textColor: #404040;
  --bodyColor: #F3F3F3;
  --borderColor: #D0D0D0;
  --placeholderColor: #737373;
  --darkColor: #000;
  --overlaycolor: #0B1A1B;
  --bgcolor: #09353D;
  --banner_img_overlaycolor: #09353D;
  --themeColor2: #09353D;
  --theme_textColor_btn: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

.mc_footbtn,
.mc_socialIcon>a,
footer ul li a {
  transition: all 0.3s;
}

.mc_footbtn:hover {
  background-color: #fff;
  color: #000;
}

input,
input:hover,
input:focus,
input:visited,
input:active {
  outline: none;
  /* box-shadow: 0px 0px 10px inset rgba(0 0 0 / 30%); */
}

/* footer ul li a:hover { */
/* color: #009fbc; */
/* } */
.mc_socialIcon>a:hover {
  /* background-color: #009fbc; */
  color: #fff;
}

.mc_dialogBox_menu .fixed.inset-0.z-50 {
  background-color: rgba(0 0 0 / 15%);
  width: 100%;
  max-width: 100%;
}

.mc_searchBar input {
  background-color: transparent;
  box-shadow: none;
  height: 38px;
  font-size: 14px;
  /* width: 350px; */
  font-weight: 400;
  color: #000000B3;
  /* padding: 0px 50px 0px 20px; */
  width: 235px;
  padding: 0px 50px 0px 8px;
}

input.mc_input {
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 7px;
  padding: 0px 45px 0 20px;
  border: 1px solid #D0D0D0;
  height: 71px;
  font-size: 16px;
  padding-top: 15px;
  width: 100%;
}

.mc_input+label {
  line-height: normal;
  height: auto !important;
  padding: 20px 20px;
  font-size: 16px !important;
  /* top: 4px !important; */
  left: 0px;
  transform: translateY(-12px);
}

input.focus.mc_input {
  outline: none !important;
  box-shadow: none !important;
}

.mc_input~.group.flex>div {
  outline: none !important;
  border: 0px !important;
  box-shadow: none !important;
}

.mc_formGroup>div {
  height: auto;
}

.mc_formGroup>span {
  position: absolute;
  right: 20px;
  top: 25px;
}

.mc_formLogo>img {
  margin: auto;
}

.mc_formBtn {
  display: inline-block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  font-size: 20px;
}

.mc_overlayBg {
  /* background: transparent linear-gradient(180deg, #000000 0%, #045362 100%); */
  /* opacity: 0.78; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* 
  font-family: 'Poppins', sans-serif;
  font-family: 'Rajdhani', sans-serif;
*/
.mc_searchBar .search_div {
  border: 0.5px solid #0000004D !important;
}

.mc_searchBar {
  position: relative;
}

.searchIcon {
  position: absolute;
  right: 10px;
  top: 14px;
}

.mc_price {
  font-size: 29px;
  font-weight: 700;
  font-family: 'Rajdhani', sans-serif;
}

body {
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Halcom', sans-serif; */
  background-color: #F3F3F3;
  -webkit-overflow-scrolling: touch;
}

.mc_dialogBox_menu {
  position: relative;
  z-index: 999;
}

/* h1,h2,h3,h4,h5,h6{
  font-family: 'Halcom', sans-serif;
} */
.bg-themeColor {
  background-color: var(--primaryColor);
}

.mc_maxTimeLine_w {
  max-width: calc(100% - 55px);
  padding-left: 20px;
}

.mc_timeLine_dv p {
  font-size: 14px;
  line-height: 21px !important;
}

.mc_timeLine_dv>ol {
  border-left: 1px dashed var(--primaryColor);
  margin-left: 28px;
  max-width: calc(100% - 28px);
}

.mc_goalContent {
  width: calc(100% - 140px);
  padding-left: 30px;
}

.mc_goalList_box {
  background: #FFFFFF;
  box-shadow: 0px 3px 3px #00000029;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.mc_goals_watch_btn {
  position: absolute;
  right: 25px;
  top: 8px;
  font-size: 12px;
  padding: 6px 15px;
  border-radius: 2px;
  background: #E1F5F8 0% 0% no-repeat;
  border: 0.7px solid #789EA5;
}

.watchBtn_mc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.watchBtn_mc svg {
  margin-right: 5px;
}

.mc_goalsSetting_dv audio {
  width: 100%;
}

.mc_goalsSetting_dv iframe {
  max-width: 100%;
}

.mc_goal_text {
  color: #393939;
  font-weight: 500;
}

.mc_goal_text.mc_active_goal {
  color: var(--primaryColor);
}

.mc_goal_text.mc_active_goal svg path {
  fill: var(--primaryColor);
}

.mc_successColor {
  color: #30AA03;
}

.mc_innerWhite_dv {
  padding: 30px;
}

/* .color-themeColor, .tab_active{ */
/* color: #01B0D0 !important; */
/* } */
.mc_rewardBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.mc_modalUpperDv {
  display: flex;
  align-items: center;
  padding: 0px 30px 10px;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 5px 5px 0px 0px;
}

.mc_modalRewardContent {
  background: #FFFFFF;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 5px;
  margin-bottom: 22px;
  position: relative;
}

.mc_rewardOverlay_dv {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(255 255 255 / 95%);
  box-shadow: 0px 1px 2px #00000029;
  border: 0.5px solid #7070702E;
  border-radius: 5px;
}

.mc_modalBody.mc_rewardModalBody {
  padding: 10px 25px;
}

.mc_lockInnner_dv {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mc_lockInnner_dv p {
  font-size: 12px;
  margin-top: 10px;
  color: #445462;
  max-width: 150px;
}

.mc_modalRewardContent .mc_moadlImage {
  width: 230px;
  background: #F7F7F7;
  border-radius: 5px 0px 0px 5px;
  height: 210px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.mc_modalRewardContent .mc_textContentModal {
  width: calc(100% - 230px);
  padding-left: 30px;
  padding-right: 30px;
}

.mc_modalRewardContent .mc_textContentModal h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.mc_modalRewardContent .mc_textContentModal p {
  font-size: 13px;
  color: #767676;
  margin-bottom: 15px;
}

.mc_modalRewardContent .mc_textContentModal .mc_rewardBatch_box p {
  margin-bottom: 0;
  margin-left: 10px;
  color: #000;
}

.mc_modalRewardContent .mc_textContentModal .mc_rewardBatch_box p b {
  display: block;
}

.mc_rewardBatch_box {
  background: #00000008;
  max-width: 50%;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
}

.mc_flexReward {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mc_rewardBatch_box .mc_batchIcon_img {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DEF1F4;
  border-radius: 4px;
  padding: 10px;
}

.mc_linkIcon_img {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DEF1F4;
  border-radius: 16px;
  padding: 4px;
  margin-right: 7px;
}

.mc_external_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 26px;
  border-radius: 4px;
  padding: 0px 10px;
  max-width: fit-content;
}

.mc_external_link>svg {
  margin-left: 8px;
  width: 15px;
}

.mc_rewardBatch_box:last-child {
  margin-left: 10px;
}

.mc_rewardBatch_box.mc_nextBOx p {
  width: 100%;
}

.mc_rewardBatch_box span img {
  max-width: 100%;
}

.mc_modalUpperDv img {
  max-width: 50px;
  margin-right: 10px;
}

.mc_modalHead_dv {
  padding-top: 10px;
}

.mc_modalHead_dv h3 {
  font-weight: 700;
}

.mc_modalHead_dv p {
  font-size: 14px;
}

.mc_innerBlueBox {
  background-color: #CAEDF6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
}

.tab_active {
  position: relative;
}

.mc_navTab nav ul li::after {
  content: '';
  display: block;
  position: absolute;
  width: 11px;
  height: 11px;
  background-color: transparent;
  border-radius: 30px;
  right: -10px;
  top: 15px;
  transition: all 0.3s;
  transform: translatey(-45px);
}

.mc_navTab nav ul li.tab_active:after {
  background-color: #000;
  transform: translatey(0px);
}

.color-themeColor>div {
  color: #057B91;
  border-color: var(--primaryColor) !important;
}

.w-height-vh {
  min-height: calc(100vh - 252px);
}

@layer components {
  .max-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  /* .mc_bgTheme { */
  /* background-color:#01B0D0; */
  /* } */
  .mc_btn {
    padding: 0px 20px;
    border-radius: 4px;
    line-height: 45px;
    height: 45px;
    color: #fff;
    /* background-color: #01B0D0; */
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    transition: all 0.3s;
    font-size: 13px;
  }

  .mc_btn:hover {
    /* background-color: #000; */
    color: #fff;
  }

  .btn-base {
    height: 36px;
    line-height: 36px;
    font-size: 13px;
  }

  /* .mc_courseBtn{ */
  /* background-color: #012329; */
  /* } */
  /* .mc_courseBtn:hover { */
  /* background-color: #01B0D0; */
  /* } */
  /* .mc_courseBtn > svg{ */
  /* fill: #fff; */
  /* } */
  .mc_bgBtn {
    /* background-color: #01B0D0; */
    line-height: 27px;
    /* color: #fff; */
    height: 27px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 7px;
  }

  /* .mc_darkColor{
    background-color: #012329;
  }
  .mc_lightDark{
    background-color: #012F38;
  } */
  .mc_notiIcon {
    line-height: normal;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 10px;
    position: relative;
  }

  .mc_notiCount {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -7px;
    right: -5px;
    font-size: 10px;
    background-color: red;
    color: #fff;
    border-radius: 10px;
  }

  .mc_tabHolder ul>li {
    max-width: fit-content;
    font-size: 16px;
    font-weight: 600;
    /* color: #000000; */
    line-height: 45px;
    position: relative;
    top: 2px;
    padding: 0px 35px;
    border-bottom: 2px solid transparent;
  }

  .mc_tabHolder ul {
    border-bottom: 2px solid #D2D2D2;
  }

  /* .mc_tabHolder ul > li.color-themeColor{ */
  /* color: #0091AB; */
  /* border-color: var(--primaryColor); */
  /* } */
  .mc_listIten {
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }

  .max-w-80 {
    max-width: 80%;
    line-height: 35px;
  }

  .mc_ulList li {
    line-height: 40px;
  }

  .mc_ulList {
    list-style: disc;
    padding-left: 20px;
    padding-top: 15px;
  }

  .line-height-20 {
    font-size: 14px !important;
    line-height: 20px;
  }

  .lightColor {
    background-color: #E3FAFF;
  }

  .mc_lightBtn {
    max-width: 350px;
    line-height: 50px;
    font-size: 14px;
    padding: 0px 10px 0 20px;
    /* border: 1px solid var(--primaryColor); */
    transition: all 0.5s;
  }

  .mc_lightBtn:hover svg,
  .mc_lightBtn:hover svg path {
    fill: #fff;
  }

  .mc_lightBtn:hover {
    background-color: var(--primaryColor);
    color: #fff;
  }

  .mc_bannerborder {
    background: #FFFFFF 0% 0% no-repeat;
    box-shadow: 0px 3px 5px #00000029;
    border: 5px solid #D2F5FC;
    border-radius: 20px 20px 4px 4px;
  }

  .mc_noteHead h5 {
    border-bottom: 1px solid #D4D4D4;
  }

  .mc_timelineBg_white {
    font-size: 10px;
    text-align: center;
    display: block;
    background-color: #fff;
    height: fit-content;
    line-height: normal;
    padding: 15px 0;
  }

  .mc_fileTimeline_dv {
    margin-top: 30px;
    margin-left: 15px;
  }

  .mc_fileTimeline_dv .mc_timelineBg_white {
    background-color: #fff;
    padding: 0 0 15px 0;
    display: flex;
    flex-direction: column;
    height: 65px;
    align-items: center;
  }

  .mc_fileTimeline_dv .mc_maxTimeLine_w {
    max-width: calc(100% - 15px);
    padding: 20px;
    margin-left: 20px;
    background: #F5F5F5;
    border-radius: 5px;
    position: relative;
    width: 100%;
  }

  .mc_fileTimeline_dv .mc_maxTimeLine_w .note_short {
    display: -webkit-box;
    max-width: 100%;
    font-size: 14px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .note_short {
    display: -webkit-box;
    max-width: 100%;
    font-size: 14px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mc_likeReply {
    margin-top: 15px;
  }

  .mc_fileTimeline_dv .mc_maxTimeLine_w span {
    color: var(--primaryColor);
    font-size: 13px;
    cursor: pointer;
  }

  .unset_listStyle ul {
    list-style: initial !important;
  }

  .unset_listStyle ol {
    list-style: decimal !important;
  }

  .mc_crossIcon {
    position: absolute;
    right: 10px;
    top: 8px;
  }

  .mc_editIcon {
    position: absolute;
    right: 35px;
    top: 8px;
  }

  .mc_editIcon svg,
  .mc_crossIcon svg {
    width: 14px;
    fill: #747474;
  }

  .mc_editIcon:hover svg,
  .mc_editIcon:hover svg path,
  .mc_crossIcon:hover svg,
  .mc_crossIcon:hover svg path {
    fill: #fab651;
  }

  .mc_boxSession {
    background: #E8FBFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    height: 71px !important;
    padding: 15px 20px;
  }

  .mc_sessionContent {
    width: calc(100% - 110px);
    padding-left: 10px;
  }

  .mc_boxSession .mc_btn {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
  }

  .mc_memberSession_device {
    max-height: 420px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.mc_viewMoreBtn {
  color: var(--primaryColor);
  font-size: 13px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.mc_pageBanner {
  background-attachment: scroll;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
}

.mc_whiteBox_courseList {
  padding: 20px;
  box-shadow: 1px 1px 5px #ccc;
  margin-bottom: 30px;
  background-color: #fff;
}

.mc_footbtn {
  padding: 0px 30px;
  line-height: 50px;
  width: fit-content;
  height: 54px;
  border: 2px solid rgba(255 255 255 / 80%);
  color: #C9C9C9;
}

.min-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 50px 15px;
}

.mc_courseListContent p {
  font-size: 12px;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mc_bgGreen {
  background-color: #22D006;
}

.mc_navTab>nav {
  width: 255px;
  border-right: 1px solid #C5C5C5;
}

.mc_navTab li div[data-projection-id],
.mc_navTab li>div.absolute {
  width: 10px;
  height: 10px;
  position: absolute;
  right: -9px;
  left: auto;
  background-color: #000;
  top: 23px;
}

.mc_courseBox {
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 5px;
  overflow: hidden;
}

.mc_content h5 {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mc_courseBox .mc_content {
  height: auto;
}

.mc_true {
  fill: var(--primaryColor);
}

.nk_roundes_20 {
  border-radius: 20px;
}

.nk_position_thumb {
  position: absolute;
  bottom: -10%;
}

/* .bg-hero { */
/* background-image: url('./assets/images/collection-background.svg'); */
/* } */
.mc_whishList_icon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.mc_whishList_icon:hover svg,
.mc_whishList_icon:hover svg path {
  fill: var(--primaryColor);
}

/************* Header Css ***********/
.mc_headerWrapper {
  height: 74px;
  background: #fff;
}

.mc_logo {
  width: 100%;
  max-width: 210px;
}

.mc_mainHeader {
  padding: 0px;
  height: 74px;
  align-items: center;
  position: fixed;
  width: 100%;
  margin: auto;
  max-width: 100%;
  box-shadow: 1px 0px 20px rgba(0 0 0 / 30%);
  z-index: 999;
  background: #fff;
}

.mc_profileMenu button>svg {
  position: relative;
  top: 10px;
}

.mc_profileMenu button {
  width: 40px;
  height: 40px;
}

.mc_btnHolder_edit .mc_btn {
  width: -moz-fit-content;
  width: fit-content;
  height: 35px;
  position: absolute;
  right: 20px;
  top: -50px;
  font-size: 12px;
}

.mc_profilePage .mc_btnHolderDv .mc_btn {
  width: fit-content;
  margin: auto 0 0 0;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}

.mc_accordianItemsNew {
  overflow: visible !important;
  padding-left: 7px;
}

.openAcc {
  height: auto !important;
  display: block;
}

.mc_profilePage span.checkTick.text-base.mc_radio {
  width: 20px;
  height: 20px;
}

.mc_profilePage .mc_checkBox_input:checked+.checkTick.mc_radio:before {
  width: 12px;
  height: 12px;
  left: 2px;
  top: 2px;
}

.mc_profilePage .mc_input {
  padding: 0px 20px;
  height: 45px;
  font-size: 14px;
}

.mc_btnHolderDv .mc_btn {
  width: fit-content;
  margin: auto 0 0 auto;
}

.mc_menuList a {
  display: block;
  line-height: 25px;
}

.mc_headerWrapper nav>ul>li>a {
  line-height: 30px;
  display: inline-block;
  padding: 0px 10px;
  border-bottom: 2px solid transparent;
}

.mc_headerWrapper nav>ul>li.mc_activeMenu>a,
.mc_headerWrapper nav>ul>li>a.active,
.mc_headerWrapper nav>ul>li:hover>a {
  border-bottom: 2px solid;
  /* color: #01B0D0; */
}

.mc_listItem_dv {
  font-size: 14px;
  /* font-weight: 600; */
}

/* .mc_tabHolder ul > li.color-themeColor .mc_listItem_dv { */
/* font-weight: 500; */
/* } */
.mc_plusIcon.mc_audioIcon_dv {
  border-radius: 5px;
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
  background-color: transparent;
}

.mc_AudioModal {
  visibility: hidden;
  opacity: 0;
}

.mc_audioIcon_dv svg {
  width: 33px;
  height: 33px;
  fill: #000;
}

audio:not([controls]) {
  display: block !important;
}

audio.mt-24.pt-24 {
  position: absolute;
  top: 130px;
  left: 300px;
  visibility: visible;
  display: block !important;
  z-index: 9999999;
}

/************ My Courses Page Css ***************/
.mc_embadIframe>iframe {
  min-height: 500px !important;
  border-radius: 10px;
}

.mc_courseImg img {
  width: 60%;
  margin: 0;
  box-shadow: 0px 1px 2px #00000029;
}

.mc_swiperHome .mc_courseImg img {
  height: 215px;
}

.mc_swiperHome .mc_courseBox {
  height: 215px;
}

.mc_courseImg {
  opacity: 0.6;
}

.mc_courseContent {
  position: absolute;
  top: 15px;
  right: 20px;
  padding: 20px;
  max-width: 70%;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 5px;
}

.mc_courseContent span.ant-progress-text {
  position: absolute;
  top: -15px;
  right: 0px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 !important;
  width: fit-content;
}

.mc_completeCourse {
  position: absolute;
  color: #52c41a;
  font-size: 12px;
  font-weight: 600;
  right: 0px;
  top: -15px;
}

.mc_progressHolder .ant-progress-bg {
  /* background-color: var(--primaryColor) ; */
  height: 4px !important;
}

.mc_progressHolder .ant-progress .ant-progress-inner {
  position: absolute !important;
}

.mc_progressHolder span.ant-progress-text {
  display: none !important;
}

.mc_courseContent .ant-progress-text:before {
  content: "[";
}

.mc_courseContent .ant-progress-inner {
  height: 5px !important;
}

.mc_courseContent .ant-progress-text:after {
  content: "]";
}

.mc_courseContent .ant-progress {
  margin: 0;
}

.mc_courseContent .ant-progress-bg {
  /* background-color: #01B0D0; */
  height: 5px !important;
}

.mc_courseContent .ant-progress-outer {
  padding: 0 !important;
  height: 5px !important;
}

.mc_content {
  height: 205px;
}

/* .mc_crossIcon:hover svg, 
.mc_crossIcon:hover svg line {
  fill: #f90000;
  stroke:#f90000;
} */
.mc_headingText {
  display: flex;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 40px;
}

.mc_border {
  border-bottom: 1px solid #e2e2e2;
}

.mc_border:last-child {
  border-bottom: 0px;
}

.mc_courseList .mc_navTab ul>li {
  max-width: 100%;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  line-height: 45px;
  position: relative;
  top: 2px;
  text-align: left;
  display: block !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 100% !important;
  padding: 0px;
}

.mc_courseList .mc_navTab ul>li.tab_active {
  font-weight: bold;
  color: #000 !important;
}

.error_mes_video_not_ava {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  text-align: center;
}

/********************* Library Css **********************/
.mc_libraryData {
  position: relative;
  top: -32px;
}

.mc_tabHolder .mc_navTab ul {
  border: 0px !important;
}

.mc_courseList .mc_navTab ul>li>div:first-child {
  width: 100%;
}

.mc_formDv {
  max-width: 450px;
  width: 100%;
  margin: auto;
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
}

.mc_backToLoginBtn {
  display: flex;
  text-align: center;
  width: 100%;
  transition: all 0.3s;
  align-items: center;
  justify-content: center;
}

/* .mc_backToLoginBtn:hover{ */
/* color: #0091AB; */
/* } */
.mc_arrowREvers svg {
  width: 10px;
  height: 16px;
}

.mc_arrowREvers {
  transform: rotate(180deg);
  margin-right: 8px;
}

.mc_logoText {
  font-size: 45px;
  font-family: fantasy;
  color: var(--primaryColor);
  line-height: 45px;
  text-align: center;
}

.mc_btnText {
  font-size: 14px;
}

.mc_errortext {
  position: relative;
  font-size: 12px;
  margin-top: 8px;
  color: #f90000;
}

.mc_authWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center top;
}

.mc_vertical_content {
  padding: 30px;
  width: 100%;
}

.icons_holder {
  display: flex;
}

.icons_holder>a:last-child {
  margin: 0;
}

/* a.mc_iconList:hover{ */
/* color: #01b0d0; */
/* } */
.icons_holder>a.mc_iconList {
  font-weight: 500;
}

.mc_postData_dv_list p {
  font-weight: 400;
}

.mc_persentage {
  position: absolute;
  right: 0;
  top: 8px;
  font-weight: 600;
  color: #22d006;
}

.mc_commentGrayBox_dv>div#accordionExample>.mc_commentGrayBox_dv {
  box-shadow: none;
  border: 0px;
  padding: 0;
}

.Toastify__toast-body {
  align-items: start !important;
}

/*********************************************/
.mc_whiteBox {
  box-shadow: 0px 3px 5px #00000017;
  border-radius: 5px;
  background-color: #fff;
}

.mc_videoDv {
  /* background: #0A0D2E; */
  border-radius: 8px;
  position: relative;
  /* min-height: 475px; */
  min-height: 504px;
}

.mc_videoDv .mc_congrats_dv {
  padding: 30px;
  /* height: 475px; */
  height: 504px;
  background-color: rgba(255 255 255 / 0.9);
}

/* .mc_videoDv .ant-slider-horizontal {
  padding-block: 4px;
  height: 12px;
  margin: 0px 5px !important;
} */
.mc_blueBox {
  margin: 0px -20px -20px;
  background: #D2F5FC;
}

.mc_imgLabel {
  display: flex;
  align-items: center;
}

label.mc_authLabel {
  display: inline-block;
  transform-origin: left;
  padding: 0 20px;
  top: 5px;
  position: absolute;
  transition: all 0.3s;
}

.ant-slider-rail {
  background-color: #FFFFFF !important;
}

.ant-slider-track {
  background-color: var(--primaryColor) !important;
}

.ant-slider-handle::after,
.ant-slider-handle.ant-tooltip-open::after {
  box-shadow: 0 0 0 2px var(--primaryColor) !important;
}

.mc_videoTracker {
  padding-bottom: 0px;
}

.mc_videoController {
  padding: 5px 20px 10px;
}

/* .mc_progressHolder .bg-gray-900 { */
/* background-color: #0091AB; */
/* } */
.mc_timeStartEnd {
  font-size: 14px;
}

/******************* Swiper Home Slicder ***************/
.mc_swiperHome .swiper {
  overflow-y: visible !important;
  position: static;
}

.mc_swiperHome {
  position: relative;
}

.mc_swiperHome .swiper-button-prev {
  position: absolute;
  left: calc(50% - 35px);
  top: calc(100% + 40px);
  width: 35px;
  height: 60px;
  background-color: #fff;
  border-radius: 50px 0 0 50px;
  border-right: 1px solid #cbcbcb;
  z-index: 99;
}

.mc_swiperHome .swiper-button-next {
  position: absolute;
  right: calc(50% - 35px);
  top: calc(100% + 40px);
  width: 35px;
  height: 60px;
  background-color: #fff;
  border-radius: 0 50px 50px 0;
  z-index: 99;
}

.mc_swiperHome .swiper-button-next:after,
.mc_swiperHome .swiper-rtl .swiper-button-prev:after,
.mc_swiperHome .swiper-button-prev:after,
.mc_swiperHome .swiper-rtl .swiper-button-next:after {
  /* display: none !important; */
  font-size: 24px !important;
  color: #3dbea9 !important;
  ;
}

.mc_swiperHome .swiper-button-next.swiper-button-disabled:after,
.mc_swiperHome .swiper-rtl .swiper-button-prev.swiper-button-disabled:after,
.mc_swiperHome .swiper-button-prev.swiper-button-disabled:after,
.mc_swiperHome .swiper-rtl .swiper-button-next.swiper-button-disabled:after {
  /* display: none !important; */
  color: #cbcbcb !important;
  ;
}

.mc_swiperHome .swiper-button-prev svg,
.mc_swiperHome .swiper-button-next svg {
  width: 18px;
  height: 18px;
}

.mc_swiperHome .swiper-button-prev.swiper-button-disabled,
.mc_swiperHome .swiper-button-next.swiper-button-disabled {
  opacity: 1;
}

.mc_swiperHome .swiper-button-next svg path,
.mc_swiperHome .swiper-button-prev svg path {
  fill: #3dbea9;
}

.mc_swiperHome .swiper-button-next.swiper-button-disabled svg path,
.mc_swiperHome .swiper-button-prev.swiper-button-disabled svg path {
  fill: #cbcbcb !important;
}

.text-green {
  color: #3DBEA9;
}

.mc_headTitle a>span>svg,
.mc_headTitle a>span>svg>path {
  fill: #3DBEA9;
}

/* .mc_profileMenu {
  min-width: 60px;
} */
/**************** Checkbox Css *****************/
input.mc_checkbox {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.mc_checkboxList {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 20px;
  font-size: 13px;
  display: inline-block;
  margin-bottom: 15px;
}

.mc_playPauseAccordian {
  position: relative;
  /* padding-left: 45px; */
  padding-right: 36px;
  cursor: pointer;
}

/* .mc_playPauseAccordian .mc_playBtn svg, 
.mc_playPauseAccordian .mc_playBtn svg path { */
/* fill: #C3C3C3; */
/* } */
/* .mc_innnerList .mc_playBtn {
  position: absolute;
  top: 15px;
} */
.mc_accordianItems {
  padding-bottom: 15px;
}

.checkTick {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 0;
  border-radius: 30px;
  top: 2px;
  background-color: #DDDDDD;
}

.checkTick:before {
  content: '';
  width: 8px;
  height: 4px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  position: absolute;
  transform: rotate(-45deg);
  left: 3px;
  top: 5px;
}

/* .mc_checkbox:checked + .checkTick,
.mc_checkboxList.mc_activeLession .checkTick{ */
/* background-color: #0091AB; */
/* } */
/********************/
.mc_listAccorfian .ant-collapse-arrow {
  position: absolute;
  right: 25px;
  width: 20px;
  height: 20px;
  justify-content: center;
  border: 0.5px solid #E9E9E9;
  border-radius: 2px;
}

.mc_listAccorfian .ant-collapse {
  border: 0;
}

.mc_listAccorfian .ant-collapse-header {
  background-color: transparent !important;
}

.mc_listAccorfian .ant-collapse {
  border: 0;
  background-color: transparent;
}

.mc_listAccorfian .ant-collapse-content.ant-collapse-content-active {
  border-top: 0;
}

.mc_listAccorfian .ant-collapse-item {
  border-radius: 0 !important;
}

.mc_listAccorfian .ant-collapse-content .ant-collapse-content-box {
  padding-top: 0;
}

.mc_heading h4 {
  line-height: 35px;
  max-width: calc(100% - 200px);
}

/********************** Batch Banner css *************************/
.mc_blueBanner {
  background-color: #6BD1E5;
  border-radius: 4px;
  padding: 145px 0 0;
  position: relative;
}

.mc_batchText.mc_redBatch {
  top: 60px;
  font-size: 20px;
  left: 0;
  right: 0;
  margin: auto;
}

.ant-slider-horizontal {
  margin: 0 !important;
  position: relative;
  top: -5px;
}

.mc_batchHolder {
  font-size: 14px;
}

.mc_batchHolder_dv {
  position: relative;
  /* padding-top: 110px; */
}

.mc_batchHolder_dv svg {
  margin-top: -120px;
}

.mc_batchHolder_dv>.mc_diamondImg {
  position: absolute;
  top: -118px;
}

.mc_midContent h3 {
  font-size: 32px;
  color: #DF0573;
  font-weight: 600;
  line-height: 40px;
}

.mc_midContent p {
  font-size: 14px;
  color: #575757;
  line-height: 15px;
  font-weight: 600;
}

.mc_tooltipIcon {
  width: 18px;
  height: 18px;
  background: #0A3B45;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  margin-left: 8px;
  position: relative;
  font-size: 13px;
}

.mc_tooltipContent {
  position: absolute;
  background-color: #0a3b45;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 200;
  left: auto;
  right: -8px;
  margin: auto;
  min-width: 100px;
  bottom: calc(100% + 7px);
  visibility: hidden;
  opacity: 0;
  padding: 4px 15px;
  transition: all 0.3s;
}

.mc_tooltipIcon:hover .mc_tooltipContent {
  visibility: visible;
  opacity: 1;
}

.mc_tooltipContent:before {
  content: '';
  position: absolute;
  width: 1px;
  border-top: 7px solid #0a3b45;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: 100%;
  right: 10px;
}

.mc_circleDark {
  width: 40px;
  height: 40px;
  background-color: #2D94A8;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}

.mc_batchText {
  position: absolute;
  z-index: 99;
  top: 15px;
  color: #fff;
  left: 0;
  right: 0;
  text-align: center;
}

/**************************/
.mc_grayBtn {
  height: 35px;
  background: #F8F8F8;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  padding: 0px 20px;
  line-height: 35px;
  display: inline-block;
  font-size: 14px;
}

.mc_playBtn {
  cursor: pointer;
}

/* .mc_playBtn span svg, 
.mc_playBtn span svg path {
  fill: #C3C3C3;
} */
.ant-collapse-item.ant-collapse-item-active .mc_playBtn span svg,
.ant-collapse-item.ant-collapse-item-active .mc_playBtn span svg path,
.mc_activeAccordian .mc_playBtn span svg,
.mc_activeAccordian .mc_playBtn span svg path {
  fill: var(--primaryColor);
}

/* .mc_accordientTxt > p {
  font-size: 16px !important;
} */
.mc_accordianBtn span svg {
  width: 18px;
  height: 18px;
}

.mc_accordianBtn {
  position: absolute;
  right: 5px;
  width: 20px;
  height: 20px;
  justify-content: center;
  border: 0.5px solid #E9E9E9;
  border-radius: 2px;
  top: 15px;
}

.mc_accordianBtn span {
  transform: rotate(0deg) !important;
  line-height: normal;
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s;
}

.mc_accordianBtn.isActive_icon span {
  transform: rotate(180deg) !important;
}

/**************** Scrollbar Css ****************/
.mc_listAccorfian {
  max-height: 560px;
  overflow: auto;
  padding-right: 5px;
  -webkit-overflow-scrolling: touch;
}

.mc_listAccorfian::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.mc_listAccorfian::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #f3f3f3;
}

.mc_listAccorfian::-webkit-scrollbar-thumb {
  background-color: #000000;
  outline: none;
  border-radius: 10px;
}

.mc_disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.mc_commentBox {
  background: #FFFFFF 0% 0% no-repeat;
  box-shadow: inset 0px 3px 3px #00000021;
  border: 1px solid #CBCBCB;
  border-radius: 4px;
  padding: 20px;
}

.mc_textarea {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

/* .mc_commentBox_text .mc_attachedFile { */
/* position: absolute;
  bottom: 10px;
  z-index: 1;
  margin: 0;
  width: fit-content;
  right: 3px; */
/* } */
.mc_attachedFile .btn-base svg {
  width: 20px;
  height: 20px;
}

/* .mc_commentGrayBox_dv .mc_attachedFile { */
/* bottom: 25px;
  right: 3px; */
/* } */
/* .mc_discussionReply .mc_commentGrayBox_dv .mc_userDetails_dv .mc_attachedFile { */
/* bottom: 20px;
  right: 5px;
  position: absolute; */
/* } */
/* .mc_commentGrayBox_dv > .mc_attachedFile { */
/* position: absolute;
  top: 40px;
  right: 25px; */
/* } */
.mc_discussionReply .mc_commentGrayBox_dv>.mc_attachedFile {
  /* position: absolute;
  top: 45px;
  right: 5px; */
  margin-top: 10px;
}

.mc_commentGrayBox_dv>.mc_userDetails_dv {
  align-items: start;
}

.mc_loadMore_holder {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.mc_attachedFile .btn-base {
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  /* padding: 0;
  width: 36px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 50px; */
}

.mc_grayBox_reply {
  background: #F4F4F4 0% 0% no-repeat;
  border: 1px solid #C6C6C6;
  border-radius: 3px;
  padding: 15px;
  position: relative;
}

.mc_grayBox_reply:before {
  content: '';
  width: 1px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 12px solid #f0f0f0;
  bottom: 100%;
  left: 125px;
  position: absolute;
}

.mc_grayBox_reply.mc_boxInnerComment {
  padding: 15px;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid rgb(3 176 208 / 17%);
  border-radius: 3px;
  background-color: #fff;
}

.mc_commentBox_text {
  width: calc(100% - 65px);
  position: relative;
}

.mc_userDetails_dv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.mc_discussionReply .mc_userDetails_dv {
  align-items: start;
}

.mc_dottedBorder:last-child {
  border: 0px;
  margin-bottom: 0;
}

.mc_dottedBorder {
  border-bottom: 1px dotted #E5E5E5;
  margin-bottom: 25px;
}

.mc_attachedFile {
  margin: 0;
  /* max-width: fit-content; */
}

.mc_tabDv_banner {
  position: absolute;
  top: -49px;
  width: fit-content;
  max-width: fit-content;
}

.mc_grayBox_reply.border-0 {
  border: 0px;
}

/* .mc_memberEmoji { */
/* position: absolute;
  right: 35px;
  top: 44px; */
/* } */
.mc_memberEmoji .emoj_mainwrapper_holder>a {
  font-size: 18px;
  line-height: 40px;
}

.comment-input-box .mc_commentGrayBox_dv .mc_commentBox_text .mc_memberEmoji {
  top: 39px;
}

/* Cutome breadCurb */

.custom_breadcrub {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  list-style: none;
}

.custom_breadcrub ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  font-weight: 400;
}

.custom_breadcrub ul li svg {
  rotate: -90deg;
  width: 18px;
}

.custom_breadcrub ul li:last-child {
  color: #000;
}

/****************** Course Detail Page Css *******************/
.mc_bannerContent_dv .mc_btn {
  max-width: 281px;
  width: 100%;
}

.mc_unLink {
  /* margin-right: 40px; */
  line-height: 25px;
  text-decoration: underline;
}

.mc_bannerimg_tag {
  width: 100%;
}

.mc_recordBox {
  padding: 35px;
  max-width: 412px;
  width: 100%;
  position: absolute;
  right: 0;
  z-index: 1;
}

.mc_playBtn .mc_coloredPlay>svg,
.mc_playBtn .mc_coloredPlay>svg path {
  fill: var(--primaryColor);
}

.mc_playBtn .mc_coloredPlay>svg {
  width: 22px;
  height: 22px;
}

/* .mc_tabCollapse { */
/* height: auto !important; */
/* } */
.mc_borseredBox {
  border: 1px solid #ccc;
}

.mc_grayBox_user {
  background: #EFEFEF;
  border-radius: 4px;
}

.mc_viewBtn {
  border-top: 1px solid #D5D5D5;
}

.mc_viewMore {
  text-align: center;
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}

.mc_whiteBox_bordered {
  background: #FFFFFF;
  box-shadow: 0px 3px 5px #00000029;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
}

.mc_ueserLists_dv {
  max-width: calc(100% - 60px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.mc_borderes_userList {
  border-top: 1px solid #e9e9e9;
}

.mc_borderes_userList:last-child {
  border-bottom: 1px solid #e9e9e9;
}

.isActiveTab .mc_grayBtn,
.mc_grayBtn:hover {
  border: 1px solid #35C0D9;
  color: #358999;
  background: #DBF7FD;
}

.mc_countNum {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  background-color: #0293AD;
  color: #fff;
  border-radius: 30px;
  bottom: -12px;
  font-size: 12px;
  line-height: 22px;
}

.innerthemeIcon svg {
  width: 15px;
  height: 15px;
}

.mc_userCountList {
  margin-bottom: 35px;
}

.mc_borderdAccordian {
  background: #F8F8F8;
  border: 1px solid #CCCCCC;
  margin-bottom: 20px;
}

.mc_borderdAccordian button {
  background-color: #F8F8F8 !important;
}

.mc_tabCollapse {
  background-color: #fff;
}

/************* Modal Css ************/
.mc_modalDialog {
  max-width: 900px !important;
  width: calc(100% - 30px);
  margin: auto;
}

.mc_modalDialog.mc_modalShort {
  max-width: 500px !important;
}

.mc_moadlHeaderImage {
  display: flex;
  align-items: center;
}

.mc_modalCloseIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 88;
}

.mc_modalBody {
  padding: 40px 15px;
}

.mc_textContentModal {
  width: calc(100% - 310px);
  padding-left: 30px;
}

.mc_moadlImage {
  width: 310px;
}

.mc_borseredBoxModal {
  border-bottom: 1px solid #d9d9d9;
}

.mc_modalDialog.mc_modalShort .mc_modalBody {
  padding: 0 15px 30px;
}

.mc_borseredBoxModal:last-child {
  border-bottom: 0px;
}

.mc_iconList {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.mc_likeReply a {
  margin: 0;
  padding-left: 0 !important;
  padding-right: 30px;
}

/* .mc_likeReply .mc_iconList >span> svg, 
.mc_likeReply .mc_iconList >span> svg path { */
/* fill: #999; */
/* } */
/* .mc_likeReply .mc_iconList >span.mc_true> svg,
.mc_likeReply .mc_iconList >span.mc_true> svg path{ */
/* fill: #0091AB; */
/* } */
.mc_discussionReply .mc_grayBox_reply.mc_boxInnerComment .mc_grayBox_reply.mc_boxInnerComment {
  padding: 0px 0 0 25px;
  font-size: 0;
  border: 0;
}

.mc_emojyList .mc_grayBtn {
  display: flex;
  min-width: 156px;
  line-height: 50px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-radius: 5px;
  margin: 0 12px;
  padding: 0px 20px 0px 10px;
  margin-bottom: 15px;
}

.mc_emojyList .mc_grayBtn:first-child {
  margin-left: 0;
}

.mc_emojyList .mc_grayBtn:last-child {
  margin-right: 0;
}

.mc_grayBtn.isActiveRate {
  background-color: #EFFFE2;
  border: 1px solid #52AD04;
}

.mc_grayBtn.isActiveRate span {
  color: #000;
}

.mc_grayBtn.isActiveRate svg,
.mc_grayBtn.isActiveRate svg path {
  fill: #000000;
}

.mc_grayBtn_submit {
  background: #B1B1B1 0% 0% no-repeat;
  border-radius: 4px;
  height: 45px;
  color: #484848;
  padding: 0px 27px;
  line-height: 45px;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  pointer-events: none;
  transition: all 0.3s;
}

.vjs-youtube .vjs-poster {
  display: none;
}

/* .mc_grayBtn_submit:hover{
  background-color: #0091AB;
  color: #fff;
} */
.mc_grayBtn_submit.isActiveBtn,
.mc_grayBtn_submit:hover {
  background-color: #000;
  color: #fff;
  pointer-events: all;
}

.mc_ratedModal {
  max-width: 700px;
  margin: auto;
  padding: 20px 0;
}

.mc_whiteBox.mx_width_330 {
  max-width: 330px;
}

/* global */
.row-item {
  cursor: pointer;
  transition: all 0.15s ease-in;
}

.row-item:hover {
  outline: 2px solid rgba(0, 0, 0, 0.6);
}

.row-container {
  display: flex;
  gap: 0.2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  position: relative;
  padding: 9;
  width: calc(100% - 50px);
  margin: auto;
  /* justify-content: center; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.row-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.title-btns {
  display: flex;
  justify-content: space-between;
  position: relative;
  /* width: calc(100% + 50px);
  left: -25px; */
}

.btnsHolder button {
  color: #373737;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in;
  width: 27px;
  height: 27px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.btnsHolder {
  display: flex;
  gap: 0.4rem;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  left: 0;
  right: 0;
  justify-content: space-between;
  top: 12px;
  z-index: 9;
}

.btnsHolder button:hover {
  /* background-color: #0091AB; */
  color: #fff;
}

.btnsHolder button:hover svg,
.btnsHolder button:hover svg path {
  fill: #fff;
}

.item-price {
  font-weight: 600;
  font-size: 2.2rem;
  margin-top: 1.4rem;
}

.link {
  text-decoration: none;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*************************** Points Modal Sidebar ************************/
.mc_sidebarModal {
  position: fixed;
  right: 0;
  top: 0px;
  width: 206px;
  z-index: 9999;
}

.mc_getPoints {
  width: 206px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50px 0px 0px 50px;
  position: fixed;
  right: 0;
  top: 0px;
  height: calc(100vh - 0px);
  z-index: 9999;
  text-align: center;
  padding: 40px 30px;
}

.mc_sidebarModal:before {
  content: '';
  right: -110px;
  top: 52px;
  width: 324px;
  height: 100vh;
  transform: matrix(0.99, -0.16, 0.16, 0.99, 0, 0);
  background: #FFC469;
  border-radius: 50px 0px 0px 50px;
  opacity: 1;
  position: absolute;
  transition: all 0.7s;
}

.mc_overlayModal {
  background: #000000 0% 0% no-repeat;
  opacity: 0.2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.mc_getPoints>img {
  margin: auto;
  display: inline-block;
  width: 55px;
}

.mc_yellowBg_box {
  background: #FFF5D8;
  border-radius: 150px 0px 0px 50px;
  opacity: 1;
  position: absolute;
  padding: 90px 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 206px;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.mc_sidebarModal {
  margin: 0px !important;
}

.mc_bottomRightSidebar .mc_sidebarModal_circle {
  max-width: 550px !important;
  overflow: visible;
  background: transparent;
  box-shadow: none;
  bottom: -95px;
  right: -85px;
}

.mc_bottomRightSidebar .mc_getCirclePoints {
  background: transparent;
  overflow: visible;
  background-position: 0px 60px;
  background-size: contain;
  background-repeat: no-repeat;
  height: auto;
  top: auto;
  right: 140px;
  bottom: 90px;
}

.mc_bottomRightSidebar .mc_modalRight_circle .mc_modalContent {
  right: -10px;
  top: -25px;
}

.mc_getPoints h1.text-5xl,
.mc_getCirclePoints h1.text-5xl {
  line-height: 47px;
  font-weight: 500;
  color: #393939;
}

.mc_fluidtext {
  line-height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.mc_circleIcon {
  position: absolute;
  top: -20px;
  width: 63px;
  height: 63px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #FFF5D8;
}
.mc_bottomContent{
  padding-bottom: 30px;
}
.mc_bottomContent img,
.mc_getCirclePoints>img {
  margin: auto;
  display: block;
}

.mc_whiteCircle {
  width: 22px;
  height: 22px;
  background: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 50px;
}

/*********** Circle Modal css *****************/
.mc_sidebarModal_circle {
  bottom: -83px;
  right: -126px;
  max-width: 550px;
  height: 550px;
  background: #FFFFFF;
  box-shadow: 0px 8px 8px #00000029;
  border-radius: 50%;
  width: 100%;
  overflow: hidden;
  top: auto;
}

.mc_bottomRightSidebar.mc_getPoints_wrapper {
  overflow: hidden;
}

.mc_getCirclePoints>img {
  margin: auto auto 10px;
}

.mc_getCirclePoints {
  width: 165px;
  height: 550px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  right: 140px;
  text-align: center;
  margin: auto;
  padding: 50px 15px;
}

/**************** Triangle Modal Css ******************/
/**************** Text Image Section Css ******************/
.mc_textImage_holder {
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  max-height: 500px;
  padding: 35px;
  overflow: auto;
  height: 500px;
  -webkit-overflow-scrolling: touch;
}

.spinner_yt {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc_imgHolder_dv {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mc_halfDv {
  max-width: 50%;
  padding-right: 30px;
}

/***************** FullScreen Modal **************/
.mc_fullScreen_modalContent {
  border-radius: 0px;
  height: 100vh;
  background-color: rgba(255 255 255 / 86%) !important;
}

.mc_fullScreen_modalContent .mc_modalBody {
  padding: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
}

.mc_congrats_dv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 11;
  padding-bottom: 50px;
}

.mc_cloudimg {
  position: absolute;
  bottom: -210px;
}

.mc_modalTitle {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 30px;
}

.mc_congrats_dv p {
  font-size: 17px;
  color: #000;
  font-weight: 500;
}

.mc_getPointsNo {
  font-size: 34px;
  font-weight: 700;
  color: #fd0560;
  line-height: 45px;
}

.mc_congrats_dv>img {
  margin: 15px;
}

.mc_whiteBtn {
  height: 47px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #0000003E;
  border: 1px solid #FD0560;
  border-radius: 4px;
  padding: 0px 30px;
  line-height: 48px;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.5s;
}

.mc_whiteBtn:hover {
  background: #FD0560;
  color: #fff;
}

.mc_completeModal {
  position: relative;
  min-height: 178px;
  height: 178px;
}

.mc_tickIcon_modal {
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;
}

/*************** Progress Bar Css ****************/
.ant-progress.ant-progress-show-info .ant-progress-outer {
  padding: 0px !important;
  margin-right: 0px !important;
}

.ant-progress.ant-progress-status-success .ant-progress-text {
  display: none !important;
}

.mc_textImage_holder::-webkit-scrollbar,
body::-webkit-scrollbar,
.mc_pageScroll_dv::-webkit-scrollbar {
  width: 6px;
  border-radius: 0px;
  background-color: #f3f3f3;
}

.mc_textImage_holder::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
.mc_pageScroll_dv::-webkit-scrollbar-track {
  box-shadow: none;
}

.mc_textImage_holder::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.mc_pageScroll_dv::-webkit-scrollbar-thumb {
  background-color: #BCBCBC;
  outline: none;
  border-radius: 10px;
  height: 5px;
}

.mc_circleActive_icon {
  width: 32px;
  height: 32px;
  border: 2px solid #C3C3C3;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mc_activeAccordian .mc_circleActive_icon {
  border: 2px solid var(--primaryColor);
}

.mc_circleActive_icon svg {
  width: 20px;
  height: 20px;
}

.mc_midContent h3.redColor {
  color: #DE3D0C;
}

.mc_circleActive_icon i:before {
  content: '';
  width: 18px;
  height: 10px;
  border-left: 2px solid #C3C3C3;
  border-bottom: 2px solid #C3C3C3;
  position: absolute;
  transform: rotate(-45deg);
  left: 7px;
  top: 21px;
}

/* .mc_playBtn span.mc_circleActive_icon.isGray_color svg, 
.mc_playBtn span.mc_circleActive_icon.isGray_color svg path, 
.mc_playBtn span.mc_circleActive_icon.isGray_color svg circle { */
/* fill: #C3C3C3; */
/* } */
/* .mc_activeAccordian .mc_playBtn span.mc_circleActive_icon.isGray_color svg, 
.mc_activeAccordian .mc_playBtn span.mc_circleActive_icon.isGray_color svg path, 
.mc_activeAccordian .mc_playBtn span.mc_circleActive_icon.isGray_color svg circle { */
/* fill: var(--primaryColor); */
/* } */
.mc_checkboxList.mc_activeLession .mc_lessonName_span {
  /* color: #C3C3C3; */
  display: inline-block;
  word-break: break-word;
  white-space: break-spaces;
}

.mc_lessonName_span {
  display: inline-block;
  /* margin-left: 5px; */
  word-break: break-all;
}

.mc_lessonName_span .mc_lesson_quiz_icon svg {
  display: inline;
  /* margin-left: 4px; */
}

/* .mc_checkboxList.mc_activeLession.isActiveLesson svg { */
/* fill: var(--primaryColor); */
/* } */
/* .mc_checkboxList.mc_activeLession.isActiveLesson .mc_lessonName_span{ */
/* color: var(--primaryColor); */
/* } */
span.mc_playTick {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  /* border: 1px solid #c3c3c3; */
  border-radius: 15px;
  top: 2px;
}

/* .mc_checkboxList.mc_activeLession .mc_checkbox:checked ~ .mc_lessonName_span,
.mc_checkboxList.isActiveLesson .mc_lessonName_span { */
/* color: var(--primaryColor); */
/* } */
.fileIcon {
  position: absolute;
  left: 0;
  /* fill: #C3C3C3; */
}

/* .mc_checkboxList.isActiveLesson .fileIcon,
.mc_checkboxList.mc_activeLession .fileIcon { */
/* fill : var(--primaryColor); */
/* } */
.mc_checkboxList svg {
  position: relative;
  top: 0;
  width: 16px;
}

.mc_passwordKey svg {
  width: 25px;
  fill: #000;
}

.mc_passwordKey svg,
.mc_passwordKey svg path {
  fill: #000;
}

span.mc_playTick:before {
  content: '';
  left: 4px;
  width: 1px;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 7px solid #c3c3c3;
  height: 1px;
  position: absolute;
  top: 3px;
}

.mc_checkbox:checked+.mc_playTick:before {
  border-left: 7px solid #C3C3C3;
}

.mc_checkboxList.mc_activeLession .mc_playTick:before {
  border-left: 7px solid var(--primaryColor);
}

.mc_activeLession span.mc_playTick {
  border: 1px solid var(--primaryColor);
}

.mc_checkbox:checked+.mc_playTick {
  border: 1px solid #C3C3C3;
}

.mc_activeLession .mc_checkbox:checked+.mc_playTick,
.mc_checkboxList.isActiveLesson .mc_playTick {
  border: 1px solid var(--primaryColor);
}

.mc_activeLession .mc_checkbox:checked+.mc_playTick:before,
.mc_checkboxList.isActiveLesson .mc_checkbox+.mc_playTick:before {
  border-left: 7px solid var(--primaryColor);
}

/* .mc_keyPageIcon svg, 
.mc_keyPageIcon svg path {
  fill: var(--primaryColor);    
  stroke: var(--primaryColor);
} */
.mc_innerMenuIcon svg {
  width: 20px;
  height: 20px;
  /* fill: #374151; */
}

/* .mc_innerMenuIcon:hover svg,
.mc_dropMenu.active .mc_innerMenuIcon svg { */
/* fill: var(--primaryColor); */
/* } */
/* .mc_innerMenuIcon:hover,
.mc_dropMenu.active .mc_innerMenuIcon{ */
/* color: var(--primaryColor); */
/* } */
.mc_innerMenuIcon.mc_logout svg,
.mc_innerMenuIcon.mc_logout svg path {
  fill: #DC3545;
}

.mc_innerMenuIcon.mc_logout {
  color: #DC3545;
}

/* span.mc_circleActive_icon.isActiive_color { */
/* border: 2px solid var(--primaryColor); */
/* } */
/* .mc_playBtn span.mc_circleActive_icon.isActiive_color svg, 
.mc_playBtn span.mc_circleActive_icon.isActiive_color svg path, 
.mc_playBtn span.mc_circleActive_icon.isActiive_color svg circle{ */
/* fill: var(--primaryColor); */
/* } */
.mc_congrats_dv .mc_courseBtn {
  width: fit-content;
  margin: 10px auto;
}

.mc_congrats_dv .mc_courseBtn span {
  margin-left: 10px;
}

/***************************/
.mc_bottomRightSidebar .mc_modalRight_circle {
  max-width: 750px !important;
  margin: 0px !important;
  background: #FCAE35;
  box-shadow: 0px 8px 8px #00000029;
  border-radius: 50%;
  height: 750px;
  top: auto;
  right: -100px;
  bottom: -140px;
}

.mc_bottomRightSidebar .mc_modalContent {
  background-color: transparent;
  box-shadow: none !important;
  height: 100%;
  background-color: #fff;
  border-radius: 100%;
  right: 0px;
  top: 0px;
}

.mc_modalRight_circle .mc_whiteBtn {
  top: -30px;
  position: relative;
}

.mc_modalRight_circle .mc_getCirclePoints {
  max-width: 305px;
  background-color: transparent;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: auto;
  left: 130px;
  bottom: 0px;
  background-position: 100% 12%;
}

.mc_modalRight_circle .mc_getCirclePoints .mc_modalTitle {
  margin-bottom: 15px;
}

.mc_bottomRightSidebar .mc_rightManImg.mc_rightImg_modal {
  width: 115px;
  left: 75px;
  bottom: 115px;
}

.mc_modalRight_circle .mc_getCirclePoints .mc_congrats_dv {
  position: absolute;
}

.mc_fullScreen_dialogBox {
  max-width: 100% !important;
  max-height: 100vh !important;
  height: 100vh !important;
  margin: 0px !important;
  /* background-color: rgba(255 255 255 / 86%); */
}

.mc_fullScreen_modal {
  background-color: transparent !important;
}

/********************* Video Slider css **********************/
.mc_videoSlider_dv {
  overflow-y: visible !important;
}

/* .mc_videoSlider_dv .swiper-wrapper {
  overflow: hidden;
} */
.mc_videoSlider_dv .swiper-pagination {
  position: absolute;
  bottom: -56px !important;
  width: fit-content !important;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  z-index: 9;
  top: auto !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mc_videoSlider_dv .swiper-pagination-fraction {
  font-size: 0px;

}

.mc_videoSlider_dv .swiper-pagination-bullet,
.mc_videoSlider_dv .swiper-pagination-fraction .swiper-pagination-current,
.mc_videoSlider_dv .swiper-pagination-fraction .swiper-pagination-total {
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  opacity: 1;
  /* display: none !important; */
  width: 50px;
  height: 20px;
  font-size: 16px;
  position: relative;
}

.mc_videoSlider_dv .swiper-pagination-bullet.swiper-pagination-bullet-active:last-child::before {
  display: none !important;
}

.mc_videoSlider_dv .swiper-pagination-bullet.swiper-pagination-bullet-active,
.mc_videoSlider_dv .swiper-pagination-bullet:last-child {
  opacity: 1;
  display: inline-block !important;
  position: relative;
}

.mc_videoSlider_dv .swiper-pagination-bullet:last-child::before,
.mc_videoSlider_dv .swiper-pagination-fraction .swiper-pagination-total::before {
  content: 'of';
  color: #fff;
  left: -10px;
  position: absolute;
}

.mc_videoSlider_dv .swiper-button-prev,
.mc_videoSlider_dv .swiper-button-next {
  position: absolute !important;
  bottom: -57px !important;
  height: 20px !important;
  width: 20px !important;
  top: auto !important;
}

.mc_videoSlider_dv .swiper-button-prev {
  left: calc(50% - 60px) !important;
}

.mc_videoSlider_dv .swiper-button-next {
  right: calc(50% - 70px) !important;
}

.mc_videoSlider_dv .swiper-button-prev:after,
.mc_videoSlider_dv .swiper-rtl .swiper-button-next:after,
.mc_videoSlider_dv .swiper-button-next:after,
.mc_videoSlider_dv .swiper-rtl .swiper-button-prev:after {
  color: #fff;
  width: 20px;
  font-size: 16px;
}

.mc_thumbSlider .swiper-button-prev:after,
.mc_thumbSlider .swiper-button-next:after {
  font-size: 22px;
  color: #000;
}

.mc_thumbSlider .swiper-horizontal {
  padding: 0px 17px;
}

.mc_thumbSlider .swiper-button-prev,
.mc_thumbSlider .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, -7px);
}

.mc_thumbSlider .swiper-button-next,
.mc_thumbSlider .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, -7px);
}

.mc_thumbSlider .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.mc_thumbSlider .swiper-backface-hidden .swiper-slide {
  opacity: 0.5;
}

.mc_thumbSlider .swiper-backface-hidden .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.mc_AudioDv {
  height: calc(100% - 67px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
}

.mc_loopHolder .mc_iconList {
  font-size: 14px;
  font-weight: 500;
}

.mc_innnerPlay_dv {
  position: relative;
}

.mc_playCenter_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: 50px;
  right: 0;
  top: 50px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 50%);
  transition: all 0.3s;
}

.mc_vidoBox_fixed {
  position: fixed;
  /* bottom: 100px; */
  right: 100px;
  width: 370px;
  z-index: 100;
}

.mc_vidoBox_fixed a.mc_playCenter_icon {
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: relative;
  width: 30px;
  height: 30px;
}

.mc_vidoBox_fixed .mc_playCenter_icon>svg {
  position: relative;
  left: 2px;
  width: 10px;
}

.mc_vidoBox_fixed .mc_leftContent {
  font-size: 12px;
}

.mc_vidoBox_fixed .mc_videoController {
  padding: 0px 20px 10px;
}

.mc_videoToggleBar {
  display: block;
  width: 100%;
  padding: 10px 10px 20px;
  border-radius: 10px 10px 0 0;
  background-color: #ccc;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10px;
}

.mc_playCenter_icon:hover {
  background-color: #009999;
}

.mc_playCenter_icon>svg {
  position: relative;
  left: 4px;
}

.mc_videoLink_icon {
  width: calc(100% - 35px);
}

.mc_videoLink_icon p,
.mc_videoLink p {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.mc_videoLink_icon span {
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #CCCCCC;
}

.mc_batchText_dv .mc_btn {
  height: 35px;
  font-size: 12px;
  line-height: 35px;
}

.mc_notiFicationModal {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  width: 450px;
}

.mc_notiText_holder {
  width: calc(100% - 100px);
}

.mc_max_notification_dv {
  max-height: 450px;
  overflow: auto;
}

.mc_uploadedAdio span {
  width: 35px;
  height: 35px;
  border: 2px solid #00d001;
  border-radius: 5px;
  margin: 0 auto 10px;
}

span.mc_notiCount_blue {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 40px;
  /* background-color: #01B0D0; */
  color: #fff;
}

/******************** Point Modal ********************/
.mc_bottomTriangleModal.mc_getPoints_wrapper {
  max-height: 100vh;
  overflow: hidden;
}

.mc_sidebarModal_Triangle {
  max-width: 700px !important;
  margin: 0 !important;
  bottom: 0 !important;
  height: 700px !important;
  background: transparent !important;
  box-shadow: none;
}

.mc_bottomTriangleModal .mc_getCirclePoints {
  width: 220px !important;
  left: auto !important;
  bottom: 0 !important;
  margin: 0 !important;
  padding: 0px 35px 70px 35px;
  right: 0 !important;
  height: -moz-fit-content;
  height: fit-content;
  top: auto !important;
  background-color: transparent !important;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: none;
}

.mc_bottomRightSidebar .mc_rightManImg {
  left: -10%;
  bottom: 94px;
  max-width: 410px;
}

.mc_bottomTriangleModal.mc_getPoints_wrapper .mc_modalContent {
  background: transparent;
  box-shadow: none;
  height: 100%;
  width: 100%;
}

.mc_bottomTriangleModal.mc_getPoints_wrapper .mc_modalContent:before {
  content: '';
  position: absolute;
  width: 1000px;
  height: 1000px;
  background: #FFFFFF;
  transform: rotate(45deg);
  top: 30%;
  left: 35%;
}

.mc_bottomTriangleModal.mc_getPoints_wrapper .mc_modalContent:after {
  content: '';
  position: absolute;
  transform: matrix(1.40, -1.8, 0.5, 0.09, 0, 0);
  background: #FE9B02;
  width: 1200px;
  top: 2px;
  height: 400px;
  left: -2px;
  z-index: -1;
  right: 105px;
  bottom: 101px;
}

.mc_rightManImg {
  position: absolute;
  bottom: 0;
  left: 20%;
  max-width: 455px;
}

/***************** Community Page Css **************/
.mc_borderedList li {
  border-right: 1px solid #e9e9e9;
}

.mc_borderedList li:last-child {
  border-right: 0px;
}

.mc_borderedList {
  padding: 15px 0px;
  border-bottom: 1px solid #f5f5f5;
}

.mc_batchImg {
  text-align: center;
}

.mc_batchImg>img {
  margin: auto;
}

.mc_slideThumb .item-header svg {
  width: 55px;
  height: 55px;
}

.mc_formWrapper {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 0;
}

.mc_textArea_form {
  max-width: calc(100% - 72px);
  width: 100%;
}

.mc_textArea_form .mc_textarea {
  width: 100%;
  padding: 15px 20px;
}

.mc_withoutBordered {
  border: 0px;
  box-shadow: none;
  outline: none;
  resize: none;
}

.mc_bordered_list {
  width: 100%;
  padding-top: 20px;
  border-top: 1px dashed #e9e9e9;
}

.mc_ucImage {
  text-align: center;
  position: relative;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.mc_ucImage span {
  display: inline-block;
}

.mc_plusIcon {
  width: 50px;
  height: 50px;
  margin: 0px auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px solid;
  border-radius: 50%;
  background: #E2E2E2;
  font-size: 31px;
  line-height: 50px;
}

.mc_ucImage .mc_withoutBordered {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}

.mc_postForm {
  background: #FFFFFF;
  box-shadow: inset 0px 3px 3px #00000029;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  overflow: hidden;
  padding: 20px;
  color: #000;
  font-size: 14px;
}

.mc_fileUpload_form {
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 6px #00000029;
  border: 1px dashed #0B8EAA;
}

.mc_closeImg {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255 255 255 / 40%);
  border-radius: 40px;
}

.mc_postData_dv {
  background: #FFFFFF 0% 0% no-repeat;
  box-shadow: 0px 0px 6px #00000015;
  border: 1px dashed #DDDDDD;
  border-radius: 4px;
}

.mc_clockGray svg {
  width: 12px;
  height: 17px;
}

.mc_clockGray svg,
.mc_clockGray svg path {
  fill: #000;
}

.mc_postData_dv_list {
  background: #FFFFFF 0% 0% no-repeat;
  border: 1px dashed #DDDDDD;
  margin-top: -2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.mc_dotedBorder_top {
  border-top: 1px dashed #B4B4B4
}

.mc_transparent_btn {
  background-color: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 4px;
  line-height: 45px !important;
  display: block;
  height: 45px;
  transition: all 0.3s;
}

.mc_transparent_btn:hover {
  background-color: #000;
  color: #fff;
}

/* .spinner-border {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
} */
.spinner-border {
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  /* background-color: #f1f1f1; */
  border-radius: 5px;
  /* margin: auto auto 0; */
  bottom: 0;
}

.spinner-border span.sr-only {
  position: relative;
  margin-left: 15px;
  width: 20px;
  height: 20px;
  border-top: 3px solid var(--primaryColor);
  border-left: 3px solid var(--primaryColor);
  border-radius: 50px;
}

div#scroller {
  /* max-height: 850px; */
  overflow: auto;
  position: relative;
  display: grid;
  gap: 25px;
  column-gap: 25px;
  -webkit-overflow-scrolling: touch;
}

div#scroller.mc_eventListHolder {
  max-height: calc(100vh - 268px);
}

.mc_audioBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  min-height: 350px;
  border-radius: 7px;
  margin-bottom: 30px;
  position: relative;
  background: transparent linear-gradient(180deg, #0895B2 0%, #046A80 100%) 0% 0% no-repeat;
}

/* .mc_commentBox_dv .mc_grayBox_reply:before {
  border: 0px;
  width: 150px;
  height: 3px;
  left: 160px;
  top: 0px;
  background-color: #01b0d0;
} */
.mc_boxInnerComment>.ml-5 {
  margin: 0;
}

.mc_commentGrayBox_dv {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px #00000029;
  border: 0.7px solid #E6E6E661;
  border-radius: 4px;
  padding: 25px;
  margin-bottom: 20px;
}

.emoj_mainwrapper_holder {
  position: relative;
  left: -5px;
  top: 0;
}

.mc_discussionReply .mc_commentGrayBox_dv {
  padding: 0px 25px;
  position: relative;
  box-shadow: none;
  border: 0;
}

.mc_notificationList {
  padding: 10px 0;
  border-bottom: 1px solid #dedede;
}

.mc_assessmentContent .mc_borderedList {
  max-width: 720px;
  margin: auto;
  text-align: center;
}

.mc_TextContentList .mc_courseBtn {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.mc_assessmentContent .mc_TextContentList {
  max-width: 650px;
  margin: auto;
}

.mc_assessmentContent .mc_borderedList li {
  border-right: 1px solid #B7B7B7;
  padding: 0px 40px;
}

.mc_assessmentContent .mc_borderedList li:last-child {
  border-right: 0px;
}

.mc_assestModal .mc_modalBody {
  padding: 0;
}

.mc_paddZero {
  padding: 0px;
}

.mc_assessmentContent {
  padding: 45px 15px;
}

.mc_innerContentHead .mc_innerContentHead {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}

.mc_modalHeader {
  background: #E7FAFD;
  border-radius: 6px 6px 0px 0px;
  padding: 30px;
  border-bottom: 1px dashed #C3D1D3;
}

.mc_questionHead {
  padding: 20px 30px;
  border-bottom: 1px solid #ededed;
}

.mc_questionHead h2 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.mc_countCircle {
  min-width: 34px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50px;
  background-color: #02A0BC;
  color: #fff;
}

.mc_innerContentHead button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  min-width: 135px;
  background: #C4C4C4;
  font-size: 14px;
}

.mc_previousBtn:hover {
  background: #02A0BC;
  color: #fff;
}

.mc_previousBtn:hover svg,
.mc_previousBtn:hover svg path {
  fill: #fff;
}

.mc_nextBtn:hover {
  background: #012329;
  color: #fff;
}

.mc_nextBtn:hover svg,
.mc_nextBtn:hover svg path {
  fill: #fff;
}

.mc_innerContentHead button.mc_transparent_btn {
  background-color: #fff;
  border-color: #006577;
  text-align: center;
  justify-content: center;
}

.mc_innerContentHead button.mc_transparent_btn:hover {
  background-color: #006577;
  color: #fff;
}

.mc_fileUpload_form img {
  max-width: 100%;
  width: 100%;
}

.mc_chatIcon svg {
  width: 15px;
  height: 13px;
}

/* .color-themeColor .mc_chatIcon svg, 
.color-themeColor .mc_chatIcon svg path{
  fill: #0091AB;
} */
/* .mc_chatIcon svg, .mc_chatIcon svg path{ */
/* fill: #000; */
/* } */
.mc_userName {
  width: calc(100% - 65px);
}

.mc_imgLeft {
  max-width: 80px;
  margin-right: 15px;
}

/* .mc_radioBtn:checked + svg, 
.mc_radioBtn:checked + svg path {
  fill: #01b0d0;
} */




















.mc_bannerInnerContent .ant-progress-text {
  display: none;
}

/**********************************************/
.mc_checkBox_input+.checkTick {
  width: 26px;
  height: 26px;
  border-radius: 0px;
  border: 2px solid #707070;
  background-color: transparent;
}

.mc_checkBox_input:checked+.checkTick {
  /* border: 2px solid var(--primaryColor); */
  background-color: #E5FBFF;
}

.mc_checkBox_input+.checkTick:before {
  visibility: hidden;
}

.mc_checkBox_input:checked+.checkTick:before {
  content: '';
  visibility: visible;
  width: 15px;
  height: 8px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  position: absolute;
  transform: rotate(-45deg);
  left: 4px;
  top: 5px;
  border-radius: 2px;
}

.mc_checkBox_input:checked+span.checkTick.text-base.mc_radio:before {
  display: none;
}

.mc_checkBox_input:checked+span.checkTick.text-base.mc_radio i {
  visibility: visible;
  width: 14px;
  height: 14px;
  border-left: 0;
  border-bottom: 0;
  position: absolute;
  transform: rotate(0deg);
  left: 4px;
  top: 4px;
  border-radius: 50px;
}

.mc_checkBox_input:checked+.checkTick.mc_radio i {
  content: '';
  visibility: visible;
  width: 14px;
  height: 14px;
  border-left: 0;
  border-bottom: 0;
  position: absolute;
  transform: rotate(0deg);
  left: 4px;
  top: 4px;
  border-radius: 50px;
  /* background-color: var(--primaryColor); */
}

.mc_checkBox_input+.checkTick.mc_radio {
  border-radius: 50px;
}

.mc_formGroup .mc_checkboxList {
  padding-left: 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* height: 30px; */
}

.mc_formGroup .mc_checkboxList label {
  padding-left: 35px;
}

.mc_answerList {
  min-height: 250px;
  padding-bottom: 180px;
  position: relative;
}

.mc_borderProgress {
  /* height: 6px; */
  position: relative;
  /* background-color: #2DBA0C; */
}

.mc_borderProgress>span>i {
  /* content: ''; */
  position: absolute;
  right: 16px;
  bottom: -17px;
  background-color: transparent;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  /* background-color: #03d080; */
}

.mc_borderProgress span {
  width: 44px;
  height: 44px;
  /* background: #2DBA0C; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 50px;
  position: absolute;
  right: -13px;
  bottom: 20px;
  font-weight: 400;
  color: #fff;
}

.mc_afterNone:after {
  right: 27px;
}

.mc_borderProgress.mc_afterNone span {
  right: 15px;
}

.mc_timeLeft {
  position: absolute;
  top: 12px;
  right: 50px;
}

.mc_circleProgress {
  width: 67px;
  height: 67px;
  border: 4px solid #4c4c4c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  /* background-color: #fff; */
  position: relative;
}

.mc_circleProgress:before {
  content: '';
  position: absolute;
  left: -4px;
  right: 0;
  top: -4px;
  bottom: 0;
  border: 4px solid #2DBA0C;
  border-radius: 50%;
  z-index: 1;
  width: 67px;
  height: 67px;
}

li.ant-breadcrumb-separator {
  align-items: center;
  display: flex;
}

text.mc_svgText,
text.mc_svgText2 {
  position: absolute;
  transform: translateY(-82px);
  font-size: 30px;
}

text.mc_svgText2 {
  transform: translateY(-50px);
}

.mc_moadlHeaderImage>svg {
  width: 45px;
  height: 45px;
}

.mc_batchText_dv img {
  width: 45px;
}

.mc_batchText_dv .mc_whiteCircle {
  width: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  top: 8px;
  left: 12px;
  border: 0;
  height: 19px;
}

.mc_batchText_dv h3 {
  font-size: 13px;
  font-family: 'Amarillo';
  color: #000;
}

.mc_modalHeader.bg-white {
  background-color: #fff;
}

.mc_br_img img {
  max-width: 300px;
  margin: auto;
  /* position: absolute; */
  /* top: -30px; */
  left: 0;
  right: 0;
}

.mc_modalBody .mc_br_img img {
  position: absolute;
  top: -30px;
}

.mc_br_img {
  position: relative;
  min-height: 160px;
}

.mc_birthDay_modal .mc_modalDialog {
  max-width: 560px !important;
}

.mc_birthDay_modal input.mc_input {
  height: 45px;
  padding: 0px 20px;
  margin-top: 7px;
}

.mc_birthDay_modal .mc_btn.mc_courseBtn {
  justify-content: center;
}

.mc_birthDay_modal .mc_modalBody {
  padding: 0px 55px 50px;
}

.mc_birthDay_modal .mc_br_img h3 {
  font-family: 'Amarillo';
  font-size: 31px;
  line-height: 55px;
}

.mc_textHolde_mod {
  position: relative;
  margin: auto;
}

.mc_birthDay_modal .mc_textHolde_mod p {
  text-align: right;
  font-size: 13px;
  position: absolute;
  bottom: 0px;
  right: 0;
  top: auto;
  font-weight: 500;
}

.mc_modalCongratulations .mc_br_img {
  position: relative;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 30px; */
}

.mc_modalCongratulations .mc_br_img img {
  margin-right: 0;
  top: -55px;
}

.mc_textHolde_mod p {
  text-align: right;
  font-size: 12px;
  position: relative;
  top: -13px;
}

span.mc_vidPlay_icon {
  width: 1px;
  border-bottom: 7px solid transparent;
  border-top: 7px solid transparent;
  border-left: 13px solid #000000;
  position: absolute;
  left: 2px;
  right: 0;
  margin: auto;
}

span.mc_plusIcon {
  position: relative;
  display: flex;
}

span.mc_plusIcon.mc_videoIcon_dv {
  border: 2px solid #000;
  border-radius: 5px;
  width: 33px;
  height: 33px;
  margin-bottom: 10px;
  background-color: transparent;
}

.mc_addInner_icon input.mc_input {
  height: 50px;
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 0 40px 0 50px;
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mc_formGroup.mc_addInner_icon {
  position: relative;
}

.mc_addInner_icon span {
  left: 15px;
  top: 15px;
  width: fit-content;
}

.mc_formGroup.mc_addInner_icon {
  position: relative;
  text-align: center;
  margin: 20px auto 10px;
}

.mc_imgLeft img {
  border-radius: 5px;
  margin-right: 15px;
}

.mc_videoLink {
  width: calc(100% - 100px);
}

.mc_crossInput {
  position: absolute;
  right: 20px;
  top: 20px;
}

/******************** DropDown Css ********************/
.mc_dropdown_wrapper {
  position: absolute;
  right: 20px;
  top: 20px;
}

.mc_dropdownMenu {
  width: 130px;
  background: #FFFFFF 0% 0% no-repeat;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
}

/**************************/
.mc_strikyBox_wrap.mc_grigBox {
  position: sticky;
  top: 90px;
  z-index: 2;
  height: fit-content;
}

.mc_circleGray {
  margin: 0 auto 15px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 50%;
}

.mc_widthShort {
  max-width: 170px;
  text-align: center;
  margin: auto;
}

/************* dot-fire *************/
/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  background-color: #10B0D0;
  color: #10B0D0;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  background-color: #10B0D0;
  color: #10B0D0;
}

.dot-pulse::before {
  box-shadow: 9974px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10024px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9974px 0 0 -5px;
  }

  30% {
    box-shadow: 9974px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9974px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10024px 0 0 -5px;
  }

  30% {
    box-shadow: 10024px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10024px 0 0 -5px;
  }
}

/**************************/
.userCongratPost {
  padding: 50px 15px;
}

.userImg_holder img {
  margin: auto;
}

.mc_partyImg_holder img {
  position: relative;
  top: -50px;
}

.userCongratPost .mc_br_img {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
}

.formImg_congrat {
  width: 100%;
}

.mc_modalCongratulations .mc_br_img .formImg_congrat img {
  position: relative;
  margin: auto;
  left: 0;
  right: 0;
  top: -32px;
}

.mc_modalCongratulations .mc_br_img .formImg_congrat.v3 img {
  top: -60px;
}

.mc_uploadProfile_dv {
  background: #E2FAFF;
  box-shadow: 0px 3px 6px #00000015;
  border: 1px dashed #10B0D0;
  border-radius: 4px;
  text-align: center;
}

.mc_uploadLabel {
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.mc_uploadLabel .mc_withoutBordered {
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.mc_profileImg_bg {
  background-position: center;
  border-radius: 10px 10px 0 0;
  height: 250px;
  background-size: cover;
}

.isActive_list {
  /* color: #0091AB; */
  position: relative;
}

.isActive_list:before {
  content: '';
  border: 0px;
  width: calc(100% + 30px);
  height: 3px;
  left: -15px;
  top: 30px;
  position: absolute;
  background-color: var(--primaryColor);
}

/* .isActive_list > span > svg,
.isActive_list  > span > svg path{
  fill: #0091AB;
} */
.mc_list>svg,
.mc_list>svg path {
  fill: #000;
  stroke: #000;
}

.mc_list>svg g {
  stroke: #000;
}

.mc_videoComponent {
  width: 100%;
  border-radius: 8px 8px;
  height: auto !important;
  aspect-ratio: 16/9;
  background-color: #ccc;
}

.ant-slider.css-dev-only-do-not-override-qgg3xn.ant-slider-horizontal {
  margin: -3px 0px 10px !important;
}

.mc_list.activeIcon>svg path,
.mc_list.activeIcon>svg,
.mc_radioBtn:checked+svg,
.mc_radioBtn:checked+svg path {
  fill: var(--primaryColor);
  stroke: var(--primaryColor);
}

.mc_list.activeIcon>svg g {
  stroke: var(--primaryColor);
}

.mc_userDetail h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.mc_radioBtn {
  display: none;
}

.mc_videoPostdv {
  position: relative;
}

.mc_videoPostdv .mc_videoComponent {
  border-radius: 10px;
}

.mc_videoPostdv span.mc_whiteCircle {
  width: 58px;
  position: absolute;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  padding-left: 5px;
  cursor: pointer;
  background-color: rgba(255 255 255 / 0.8);
}

/*************** Loader ***************/
.pxa_no-ticket-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  margin: 30px 0;
  text-align: center;
}

.pxa_no-ticket-wrapper h4 {
  margin-top: 20px;
}

.ant-skeleton.ant-skeleton-element {
  margin-top: 30px;
}

.row-item:hover {
  outline: 0px !important;
}

.ant-skeleton.ant-skeleton-element .ant-skeleton-image,
.ant-skeleton.ant-skeleton-element {
  width: 100%;
  height: 100%;
  line-height: normal;
}

.mc_discussionReply {
  border-radius: 4px;
  /* overflow: hidden; */
  margin-top: 18px;
}

.mc_discussionReply .mc_grayBox_reply.mc_boxInnerComment {
  box-shadow: none;
  border: 0;
  padding: 0 0 0 20px;
  background-color: transparent;
}

.mc_discussionReply .mc_grayBox_reply.mc_boxInnerComment .rounded-lg {
  padding: 0px 0 0 25px;
  font-size: 0;
}

.hide.mc_loadMore_holder {
  display: none;
}

.mc_commentBox_overlay {
  overflow: visible !important;
}

.mc_commentBox_overlay .mc_grayBox_reply:before {
  left: 110px;
}

.mc_flexbox_left .mc_moboilCol_12>p {
  font-size: 15px;
  margin-right: 25px;
}

.mc_spanIcon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #e4f8fc; */
  border-radius: 6px;
}

.mc_courseListContent h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  /* line-height: 12px; */
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  display: inline-block;
}

/* .mc_spanIcon.mc_iconblue svg{ */
/* fill: var(--primaryColor); */
/* } */
/* .mc_spanIcon.mc_iconblue{ */
/* background-color:#e4f8fc; */
/* } */
/* .mc_spanIcon.mc_iconblue svg,
.mc_spanIcon.mc_iconblue svg path { */
/* fill: var(--primaryColor); */
/* } */
.mc_spanIcon svg {
  /* fill: var(--primaryColor); */
  width: 20px;
}

.post__content a {
  word-break: break-all;
  /* color: var(--primaryColor); */
}

.post__content * {
  text-align: unset !important;
}

.mc_grayBox_reply.mc_boxInnerComment {
  font-size: 0;
}

/**************** Change Password Css ****************/
.mc_changePassword_box {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  padding: 30px;
  width: 100%;
}

.mc_inputHolder .mc_input {
  padding: 0 40px 0 20px;
  height: 40px;
  margin-top: 8px;
  border: 0.5px solid rgb(0 0 0 / 30%);
  border-radius: 4px;
  box-shadow: none;
}

.mc_fieldIcon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.mcRequirements ul {
  list-style: disc;
  padding-left: 20px;
}

.mcRequirements li {
  line-height: 26px;
}

.mc_btnHolder_Dvend button.mc_btn {
  height: 45px;
  line-height: 45px;
  margin-top: 20px;
}

.mc_completeModal.text-center img {
  margin: auto;
}

.mc_btnModal_holder .mc_btn.mc_courseBtn {
  width: 170px;
  margin: auto;
}

/***************** Footer Css **************/
.mc_lightDark {
  /* background-color: #012F38; */
  position: fixed;
  width: 100%;
  bottom: 0;
}

.footerDv_fix {
  position: relative;
  z-index: 12;
}

.mc_pageScroll_dv.mc_breadCum_page {
  height: calc(100vh - 175px);
  -webkit-overflow-scrolling: touch;
}

.mc_pageScroll_dv {
  height: calc(100vh - 130px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mc_fullScreen_modal.mc_restrictionModal+.transition-all {
  z-index: 20;
}

.mc_headerWrapper {
  position: relative;
  z-index: 105;
}

.mc_fullScreen_modal.mc_restrictionModal {
  z-index: 21;
}

.mc_fullScreen_modal.mc_restrictionModal.bg_imageCongratulation {
  z-index: 21;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: rgba(255 255 255 / 86%) !important;
}

.mc_fullScreen_modal.mc_restrictionModal.bg_imageCongratulation .mc_fullScreen_modalContent {
  background-color: transparent !important;
}

.mc_tableResponsive {
  width: 100%;
  overflow: auto;
}

.mc_tableResponsive table {
  min-width: 100%;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;
}

.mc_changePassword_box {
  width: 100%;
}

.mc_courdeImg {
  /* height: 200px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

.mc_courdeImg img {
  max-height: 250px;
  width: 100%;
  height: 100%;
}

.mc_bannerimg_tag {
  width: 100%;
  max-height: 275px;
  overflow: hidden;
}

.mc_logo img {
  max-height: 70px;
  width: auto;
  max-width: 180px;
}

.mc_tableResponsive table tr {
  margin-bottom: 15px;
  width: 100%;
  background-color: #fff;
  border: 0px;
  border-radius: 4px;
}

.mc_blockSpan {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0 0 0 / 0.5);
}

.mc_tableResponsive table tr td {
  font-size: 13px;
  font-weight: 500;
  max-width: 170px;
  white-space: normal;
  word-break: break-all;
}

.mc_tableCount {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.mc_embersUrl iframe,
.mc_embersUrl video {
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

.mc_downLoad_btn {
  padding: 5px;
  border-radius: 7px;
  font-size: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin-bottom: 20px;
}

.mc_downLoad_btn svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/********************* Invite Code Modal *********************/
.mc_currentInviteCode {
  height: 56px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px dashed;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 23px;
  letter-spacing: 11.5px;
  color: #000000;
  padding: 0px 20px
}

.mc_inviteCodeModal_dv .mc_textContentModal {
  width: 100%;
  text-align: center;
  padding: 0;
  max-width: 400px;
  margin: 0 auto;
}

.mc_inviteCodeModal_dv .mc_moadlImage {
  width: 100%;
  margin-bottom: 30px;
  display: block;
}

.mc_inviteCodeModal_dv .mc_moadlImage img {
  margin: auto;
}

.mc_inviteCodeModal_dv .mc_modalContent {
  max-width: 590px;
}

/* .mc_inviteCode_holder {
  margin-top: 30px;
} */
.mc_inviteCodeModal_dv .mc_moadlImage svg {
  max-width: 100%;
}

.post__content * {
  /* word-break: break-all; */
  word-break: keep-all !important;

}

/************* Video Overlay *************/
.ytp-popup.ytp-contextmenu {
  display: none !important;
}

.overlayDv {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

.YoutubeVideoApp button {
  position: relative;
  bottom: -42px;
  left: 20px;
  z-index: 99;
}

.mc_videoDv iframe {
  border-radius: 8px;
}

/* .mc_videoDv iframe.aspectRatio {
  aspect-ratio: 16/10;
} */
.video-js button.vjs-big-play-button {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.video_fullscreen .mc_pageScroll_dv.mc_breadCum_page {
  height: 100vh !important;
}

.mc_videoComponent.aspectRatio iframe {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}

.video-js {
  border-radius: 10px;
  overflow: hidden;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: visible !important;
  transition: visibility 1s, opacity 1s;
  background-color: #2b333f !important;
}

#videoJsPlayer .vjs-controls-disabled .vjs-control-bar,
.vjs-using-native-controls .vjs-control-bar,
.vjs-error .vjs-control-bar {
  display: flex !important;
}

.vjs-error-display.vjs-modal-dialog {
  display: none !important;
}

.vjs-control-bar .vjs-loading-spinner {
  display: none !important;
}

.embedVideo iframe,
.mc_videoDv .YoutubeVideoApp iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9;
}

/* Uploade Profile Image Css ***/
.uploadprofile_img .profile-pic {
  width: 200px;
  max-height: 200px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.uploadprofile_img .file-upload {
  display: none;
}

.uploadProfile_Label {
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 7px;
  padding: 0px 45px 0 20px;
  border: 1px solid #D0D0D0;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  display: block;
}

.uploadIcom {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 16px;
  width: 220px;
}

.uploadIcom svg {
  margin-right: 10px;
}

.uploadProfile_Label input#profileImage {
  display: none;
}

.uploadprofile_img .p-image {
  display: flex;
  align-items: center;
  color: #666666;
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}

.p-image .circle {
  margin-left: 15px;
}

.uploadprofile_img .p-image:hover {
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}

.uploadprofile_img .upload-button {
  font-size: 1.2em;
}

.uploadprofile_img .upload-button:hover {
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  color: #999;
}

div#videoJsPlayer div[data-vimeo-initialized] iframe {
  aspect-ratio: 16 / 9.3;
  height: 100%;
  width: 100%;
}

.video-js.vjs-paused.vjs-vimeo {
  aspect-ratio: 16/9;
  height: auto !important;
}

.mc_profileImg_bg {
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
}

.video-js.vjs-fullscreen {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
}

.footer_head_text {
  width: 95%;
  word-break: break-all;
}

/**=========== Event Component Css Start =============*/
iframe#vimeo-video_youtube_api,
div#vimeo-video_vimeo_api iframe {
  pointer-events: none !important;
}

.video_fullscreen div#vimeo-video_vimeo_api iframe,
.video_fullscreen iframe#vimeo-video_youtube_api {
  height: 100vh !important;
  background-color: #000 !important;
}

.video_fullscreen div#videoJsPlayer {
  height: 100vh;
}

.video_fullscreen .mc_pageScroll_dv.mc_breadCum_page {
  height: 100vh !important;
}

.video-js .vjs-control-bar {
  height: 4em;
  background-color: #1a1919 !important;
  /* background-color: transparent; */
  padding: 0 10px;
}

.vjs-button>.vjs-icon-placeholder:before {
  font-size: 2.2em;
}

.video-js .vjs-play-control:hover {
  background-color: #00adef;
}

.mc_linkBack {
  padding: 0 20px;
  margin-top: 50px;
  display: block;
  text-align: center;
  color: #fff;
}

.mc_eventLogin {
  position: fixed;
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  z-index: 99;
  padding: 25px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mc_componentBody_dv {
  height: 100vh;
  width: 100vw;
}

.mc_eventlogo img {
  max-height: 50px;
  margin: 0 auto 5rem;
}

.mc_eventForm .mc_input {
  padding: 0 20px;
  line-height: 45px;
  height: 45px;
  margin-bottom: 32px;
  border-radius: 2px 2px 2px 2px;
}

.mc_eventlogo .mc_btn {
  width: 100%;
  margin-top: 25px;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.mc_eventForm label {
  color: #000000;
  margin-bottom: 5px;
  display: block;
  font-size: 12px;
  font-weight: 500;
}

.mc_OptionalInput {
  width: 38px;
  height: 38px;
  margin: 0 auto 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3D475D;
  border-radius: 50%;
  color: #8F9FBF;
}

.mc_eventlogo {
  max-width: 450px;
  width: 100%;
  padding: 100px 0;
}

.mc_eventBody {
  height: 100%;
  max-height: calc(100vh - 140px);
  width: 100%;
  padding: 10px 20px 0;
}

.msg-body {
  padding: 0 15px;
}

.mc_headTitle_dv .mc_btn {
  width: fit-content;
  height: 40px;
}

.mc_headTitle_dv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 10px;
}

.mc_headTitle_dv h2 {
  font-size: 14px;
  font-weight: 700;
  padding: 0px 0px 0px;
  color: #000 !important;
}

.mc_eventList.mc_itsMe {
  /* border-top: 1px solid #2B3345;
  background-color: #374258; */
  border-radius: 0;
}

.mc_notificationList_dv {
  padding: 10px 20px;
  max-height: calc(100vh - 140px);
  overflow: auto;
}

.mc_notificationList_dv .chat-list .flex-grow-1 {
  width: calc(100% - 60px);
}

.mc_notificationList_dv span.mc_ChatIcon_list {
  font-size: 11px;
  color: #4cd6b7;
  font-weight: 500;
}

.mc_notificationList_dv .chat-list>a {
  padding: 15px 0;
  border-bottom: 1px dotted rgb(0 0 0 / 10%);
  align-items: start;
}

.mc_notificationList_dv .mc_chatUser_content p {
  overflow: visible;
  white-space: normal;
  text-align: justify;
}

.mc_notificationList_dv .mc_chatUser_content {
  width: calc(100% - 70px);
}

.mc_notificationList_dv .chat-list p {
  font-size: 12px;
  max-width: 100%;
}

.mc_daynotify {
  color: #8B8B8B;
  font-size: 13px;
}

.mc_notifiyCount {
  display: block;
  font-style: normal;
  color: #fff;
  background-color: #000;
  width: fit-content;
  padding: 0 5px;
  margin-top: 5px;
  border-radius: 3px;
  font-size: 10px;
}

.mc_notificationList .mc_chatUser_content {
  width: calc(100% - 45px);
}

/* .mc_eventList:last-child{
  border-bottom: 1px solid #2B3345;
} */
.mc_eventList {
  padding: 15px;
  display: block;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px #00000029;
  border-radius: 4px;
}

.mc_eventListHolder,
.mc_speakerList_dv {
  max-height: calc(100vh - 260px);
  overflow: auto;
}

.mc_manageList_content {
  display: flex;
}

.mc_eventImg {
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-width: 70px;
}

.mc_eventImg>img {
  width: 60px;
  height: 60px;
  border-radius: 7px;
}

.mc_chatBox_icon {
  width: 100%;
  height: auto;
  border-radius: 7px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 500;
}

.mc_chatIcon_list {
  width: 100%;
  background: #F0FAF8;
  border: 0.699999988079071px solid #B3E0D6;
  border-radius: 3px;
  margin-top: 10px;
  color: #4A5251;
}

.mc_contentManage p {
  color: #4A5251;
}

.mc_contentManage>a {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

.mc_chatBox_icon>svg {
  fill: #8f9fb0;
  width: 27px;
  height: 27px;
}

/* .mc_eventList:nth-child(2n){
  background-color: transparent;
}
.mc_eventList:nth-child(2n) .mc_chatBox_icon{
  background-color: transparent;
} */
.mc_mobileFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  background: #FFFFFF 0% 0% no-repeat;
  box-shadow: 0px 2px 10px #00000029;
}

.mc_footerIcon_list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 5px 10px;
}

.mc_footerIcon_list li {
  margin: 0px 5px;
  position: relative;
}

.mc_notification_count {
  width: 18px;
  height: 18px;
  font-size: 9px;
  background-color: #22CCA6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  color: #000;
  line-height: 25px;
  position: absolute;
  right: 5px;
  top: -7px;
  font-style: normal;
}

.mc_footItems {
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 40px; */
  width: fit-content;
  max-width: 100px;
  flex-wrap: wrap;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
}

.mc_formCrossIcon .mc_formGroup {
  width: calc(100% - 40px);
}

.mc_formCrossIcon {
  position: relative;
}

.mc_hidefeild {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 24px;
  cursor: pointer;
}

.mc_pushNofification label {
  font-size: 14px;
  font-weight: 500;
}

/* .mc_footItems > svg{
  height: 25px;
  width:30px;
} */
.mc_footItems span {
  position: relative;
  font-size: 10px;
  font-weight: 400;
  bottom: 0px;
  line-height: normal;
  width: 100%;
  text-align: center;
}

.mc_eventHeaderMenu {
  padding: 10px 20px 10px 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 1px #00000029;
  background-color: #fff !important;
  position: sticky;
  top: 0;
  z-index: 99;
}

.mc_eventHeaderMenu ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mc_eventHeaderMenu ul li a,
.mc_speakerList_dv ul li a {
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 0px;
  margin-left: 0px;
  display: flex;
  position: relative;
}

.mc_profileHead_img {
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.mc_speakerList_dv ul {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
}

.mc_speakerList_dv ul li {
  max-width: max-content;
  width: calc(100% - 20px);
  margin: 15px 0px;
}

.mc_speakerImg {
  width: 100%;
  display: inline-block;
  position: relative;
}

.mc_speakerImg span {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  margin: auto;
  text-align: center;
  font-weight: 300;
}

.mc_dateTime {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mc_calangerList,
.mc_timeList {
  display: flex;
  align-items: center;
}

.mc_calangerList svg,
.mc_timeList svg {
  margin-right: 5px;
  fill: #8f9fbf;
}

span.mc_timeList {
  padding-left: 15px;
  border-left: 1px solid #adadad;
}

span.mc_calangerList,
span.mc_timeList {
  line-height: 30px;
}

.mc_holdContent h5 {
  color: #000 !important;
  font-weight: 600;
  line-height: 20px;
}

.mc_holdContent p {
  color: #0B9D7C;
  font-size: 11px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mc_holdContent {
  max-width: calc(100% - 120px);
  flex-direction: column;
  display: flex;
  justify-content: center;
}

/* .mc_speakerData:first-child{
  padding-top: 0;
} */
.mc_holdImage>img {
  position: absolute;
  bottom: 0;
  max-height: 120px;
  width: 100%;
  height: 100%;
}

.mc_holdImage {
  width: 100px;
  position: relative;
}

.mc_speakerData {
  border-bottom: 0;
  /* padding-top: 10px; */
  background: #FFFFFF;
  box-shadow: 0px 0px 3px #00000038;
  border-radius: 4px;
  margin-bottom: 20px;
  height: 105px;
  position: relative;
  margin-top: 36px;
}

.mc_speakerData:last-child {
  border-bottom: 0px;
}

.mc_profileSection {
  max-height: calc(100vh - 205px);
  overflow: auto;
}

.mc_formGroup .uploadProfile_Label {
  width: 100%;
  background-color: #fff;
  border-radius: 2px 2px 2px 2px;
}

.mc_profilePic_holder p {
  font-size: 14px;
  color: #494F4D;
}

.mc_profilePic_holder h3 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 15px;
  color: #000;
}

.mc_formGroup .uploadIcom {
  font-size: 15px;
  color: #000;
  width: 100%;
}

/*====================================================*/
@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.mc_loadMore_fv {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.msg-body ul li.mc_loadMore_fv {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border: 2px dotted #22cca6;
  border-radius: 30px;
  border-bottom: 5px dotted #22cca6;
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
}

/************ Responsive Css *************/
@media(max-width:1280px) and (min-width:960px) {
  .mc_mainHeader ul {
    column-gap: 0;
    width: 100%;
    padding: 0px 20px;
  }

  .mc_headerWrapper nav>ul>li>a {
    font-size: 13px;
  }

  .mc_logo {
    width: 100%;
    max-width: fit-content;
  }

  .max-w-80 {
    max-width: 100%;
  }
}

@media(max-width:1366px) {
  .mc_tabHolder ul>li {
    padding: 0px 15px;
  }
}

@media(max-width:1280px) {
  .mc_recordBox {
    position: relative;
  }

  .max-container {
    max-width: calc(100% - 30px);
  }

  .mc_tabHolder ul>li {
    padding: 0px 10px;
    font-size: 14px;
  }

  .mc_tabDv_banner {
    position: relative;
    top: 0;
    margin-top: 30px;
  }

  .mobileD_none {
    display: none;
  }

  .mc_moboilCol_12.pb-24 {
    padding-bottom: 20px;
  }
}

@media(max-width:1023px) {
  .mc_recordBox {
    position: relative;
  }

  .mc_moboilCol_12 {
    max-width: 100% !important;
    width: 100% !important;
    grid-column: span 4 / span 4;
  }

  .mc_mobileRow {
    flex-wrap: wrap !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .mc_strikyBox_wrap.mc_grigBox {
    position: relative;
    top: 0;
  }

  .mc_logo {
    width: 100%;
    max-width: 100%;
  }
}

@media(max-width:960px) {
  .mc_logo {
    width: 100%;
    max-width: 100%;
  }

  .mc_heading.mc_titleRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: center;
  }

  .mc_heading .mc_btn {
    text-align: center;
    justify-content: center;
  }

  .mc_titleRow .mc_courseBtn {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media(max-width:991px) {
  .mc_modal_wrapper>div {
    max-width: 95%;
    margin: auto;
  }

  .mc_courseImg img {
    min-height: 230px;
  }

  .course_header nav {
    justify-content: flex-end;
  }

  .mc_heading h4 {
    line-height: 35px;
    max-width: calc(100% - 0px);
    text-align: left;
  }

  .mc_tabHolder ul>li {
    font-size: 12px;
    padding: 0 10px;
    width: fit-content;
    line-height: 30px;
  }

  .grid.grid-cols-3.gap-4.mobileGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .mc_videoDv {
    min-height: auto;
  }

  .mc_tabHolder ul {
    border-bottom: 0;
  }

  .video-js .vjs-tech {
    /* height: auto !important; */
  }
}

@media(max-width:768px) {
  section#contact-us {
    flex-direction: column;
  }

  .mc_formDv {
    padding: 15px;
  }

  .max-container {
    max-width: calc(100% - 30px);
    margin: 0 auto;
  }

  .max-w-80 {
    max-width: 100%;
  }

  .mc_titleRow.mc_bannerRow {
    flex-direction: column;
  }

  .mc_ulList li {
    line-height: normal;
    margin-bottom: 20px;
  }

  .mc_dialogBox_menu .py-6 {
    padding-bottom: 0;
    padding-top: 0;
  }

  .mc_profileGrid .col-span-2 {
    grid-column: span 3 / span 3;
  }

  .mc_heading h4 {
    line-height: 25px;
    max-width: calc(100% - 0px);
    text-align: left;
    /* margin-bottom: 15px; */
    font-size: 20px;
  }

  .mc_heading.mc_titleRow {
    margin-bottom: 0px;
  }

  .mc_embersUrl iframe,
  .mc_embersUrl video {
    height: 350px;
  }

  .buttonsHolders {
    margin-top: 15px;
  }

  .mc_tableResponsive table tr {
    display: grid;
    padding: 15px 15px 15px 50px;
    position: relative;
    margin-bottom: 25px;
  }

  .mc_tableResponsive table tr td {
    max-width: 100%;
    padding: 0px;
  }

  .mc_tableResponsive table tr td {
    max-width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #ededed;
  }

  .mc_tableResponsive table tr td:last-child {
    border-bottom: 0px;
  }

  .mc_tableResponsive table tr td:first-child {
    position: absolute;
    left: 5px;
    top: 15px;
    border-bottom: 0px;
  }

  .mc_tableCount {
    width: 35px;
    height: 35px;
  }
}

@media(max-width:640px) {
  .mc_mainNav {
    display: none;
  }

  .mc_moadlImage {
    display: none;
  }

  .mc_textContentModal {
    width: calc(100% - 0px);
    padding-left: 0px;
  }

  .mc_bottomRightSidebar .mc_rightManImg.mc_rightImg_modal {
    display: none;
  }

  .mc_modalRight_circle .mc_getCirclePoints {
    max-width: 100%;
    top: 0;
    left: 0;
    bottom: 0px;
    background-position: top;
  }

  .mc_bottomRightSidebar .mc_modalRight_circle {
    max-width: 100% !important;
    border-radius: 0;
    height: 100%;
    top: auto;
    right: 0;
    bottom: 0;
  }

  .mc_bottomRightSidebar .mc_modalRight_circle .mc_modalContent {
    right: 20px;
    top: 20px;
    border-radius: 10px;
    max-width: calc(100% - 40px);
    left: 20px;
    max-height: calc(100% - 40px);
  }

  .mc_bottomRightSidebar .mc_sidebarModal_circle {
    max-width: 100% !important;
    bottom: 0px;
    right: 0px;
    border-radius: 0;
  }

  .mc_bottomRightSidebar .mc_modalContent {
    border-radius: 0;
    right: 0px;
    top: 0px;
  }

  .mc_bottomRightSidebar .mc_rightManImg {
    display: none;
  }

  .mc_bottomRightSidebar .mc_getCirclePoints {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .mc_formGroup .mc_checkboxList {
    padding-left: 0px;
    margin-right: 15px;
  }

  .isActive_list:before {
    display: none;
  }

  .grid.grid-cols-3.gap-4.mobileGrid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .footerDv_fix ul li {
    font-size: 12px;
  }
}

@media(max-width:575px) {
  .mc_logo img {
    max-height: 45px;
    width: auto;
    max-width: 125px;
  }

  .mc_mainHeader {
    padding: 15px 0 0;
  }

  .mc_tabDv_banner {
    width: 100%;
    display: flex;
    max-width: 100%;
  }

  .mc_tabDv_banner .mc_tabHolder ul,
  .mc_tabDv_banner .mc_tabHolder,
  .mc_tabDv_banner .mc_tabHolder li {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  .mc_lightDark ul li {
    padding: 0px;
  }

  .mc_lightDark ul li.px-3 {
    padding: 0px 0px;
    font-size: 12px;
  }

  .mc_goalContent {
    width: calc(100% - 0px);
    padding-left: 0px;
    padding-top: 30px;
  }

  .mc_tick_themecolorSvg svg path {
    fill: var(-);
  }

  .mc_listItem_dv {
    font-size: 14px;
    line-height: 35px !important;
    display: block;
  }

  .mc_imgHolder_dv {
    flex-wrap: wrap;
  }

  .mc_halfDv {
    max-width: 100%;
    padding-left: 0;
    padding-bottom: 30px;
  }

  .mc_bottomTriangleModal.mc_getPoints_wrapper .mc_modalContent:before {
    transform: rotate(0deg);
    top: 0%;
    left: 0%;
  }

  .mc_bottomTriangleModal.mc_getPoints_wrapper .mc_modalContent:after {
    display: none;
  }

  .mc_sidebarModal_Triangle {
    max-width: 100% !important;
    margin: 0 !important;
    bottom: 0 !important;
    height: 500px !important;
    background: transparent !important;
    box-shadow: none;
  }

  .mc_assessmentContent .mc_borderedList li {
    padding: 0px 10px;
  }

  .text-4xl {
    font-size: 24px;
    line-height: 25px;
  }

  .mc_formGroup {
    flex-wrap: wrap;
  }

  .mc_questionHead {
    padding: 10px 10px;
  }

  .mc_innerContentHead button {
    width: 100%;
    line-height: 40px;
    margin-bottom: 15px;
    margin-right: 0px;
  }

  .mc_buttonHolde_dv,
  .mc_buttonHolde_dv .flex {
    flex-wrap: wrap;
    width: 100%;
  }

  .mc_flexbox_left p {
    font-size: 14px;
  }

  .mc_batchHolder {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .mc_socialIcon {
    gap: 5px;
  }

  .mc_innnerPlay_dv>span svg {
    width: 100px;
  }

  .mc_playCenter_icon {
    width: 40px;
    height: 40px;
    left: 30px;
    top: 57px;
  }

  .mc_playCenter_icon>svg {
    width: 12px;
    left: 2px;
    position: relative;
  }

  .mc_vidoBox_fixed {
    /* bottom: 100px; */
    right: 20px;
    width: 270px;
  }

  .mc_br_img img {
    max-width: 250px;
  }

  .mc_dialogBox_menu .fixed {
    max-width: 250px;
  }

  .mc_changePassword_InnerDv .grid.grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 0;
  }

  .mc_changePassword_InnerDv .mt-5.px-3 {
    padding: 0;
  }

  .mc_changePassword_InnerDv .pr-3 {
    padding: 0;
  }

  .mc_profileGrid .grid.grid-cols-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .mc_lightDark>.max-container.py-6 {
    padding: 10px 0;
    flex-direction: column;
  }

  .footerDv_fix ul {
    margin-bottom: 0;
    justify-content: center;
  }

  .mc_lightDark ul li {
    padding: 0;
    font-size: 12px;
  }

  .mc_profileMenu button {
    width: 30px;
    height: 30px;
  }

  .mc_socialIcon {
    width: fit-content;
    margin: 5px auto 0;
  }

  .mc_socialIcon a {
    width: 30px;
    height: 30px;
  }

  .mc_socialIcon a img {
    width: 15px;
  }

  .mc_searchBar input {
    height: 30px;
    font-size: 12px;
    width: 160px;
  }

  .searchIcon {
    right: 0px;
    top: 14px;
  }

  .mc_searchBar>div {
    /* float: left;
    display: block; */
  }

  .mc_mainNav .leading-normal {
    line-height: normal;
  }

  .mc_videoDv .mc_congrats_dv,
  .mc_completeModal {
    height: auto;
  }

  .mc_profileGrid .col-span-2 {
    padding: 5px 0;
  }

  .uploadProfile_Label {
    width: calc(100% - 40px);
  }

  .mc_profilePage .px-4 {
    padding: 5px 0;
  }

  .uploadIcom {
    font-size: 12px;
    width: 170px;
  }

  .mc_changePassword_box {
    padding: 15px;
  }

  .mc_textImage_holder {
    padding: 10px 0;
  }
}

@media(max-width:450px) {

  /* .mc_searchBar {
    display: none;
  } */
  .mc_vertical_content {
    padding: 30px 0;
  }

  .mc_tabHolder ul>li {
    width: 100%;
    max-width: 100%;
    line-height: 30px;
  }

  .mc_tabHolder ul {
    display: block;
  }

  .text-sm {
    font-size: 12px;
    line-height: 16px;
  }

  .mc_modalBody {
    padding: 25px 0px;
  }

  .mc_modalDialog.mc_modalShort .mc_modalBody {
    padding: 0 0px 30px;
  }

  .mc_footbtn {
    padding: 0px 20px;
    line-height: 40px;
    height: 45px;
    font-size: 14px;
  }

  .mc_embersUrl iframe,
  .mc_embersUrl video {
    height: 200px;
  }

  /* .mc_whiteBox{
    margin-top: 30px;
  } */
  .mc_lightDark ul li:nth-child(2n) {
    display: none;
  }

  .footerDv_fix ul {
    margin-bottom: 0;
  }

  .mc_courseListContent h3 {
    font-size: 16px;
  }

  .mc_lightDark ul li {
    margin-bottom: 3px;
  }

  .max-container {
    max-width: calc(100% - 20px);
  }

  .mc_lightDark ul li,
  .mc_lightDark ul li.px-3 {
    font-size: 10px;
    margin-bottom: 0;
  }

  .mc_modluldv .ml-12 {
    margin-left: 15px;
  }

  .mc_sessionContent {
    width: calc(100% - 65px);
  }

  .mc_boxSession .mc_btn,
  .mc_boxSession {
    padding: 0 10px;
  }

  .mc_inviteCodeModal_dv .mc_moadlImage svg {
    max-width: 100%;
    max-height: 200px;
  }

  .mc_currentInviteCode {
    font-size: 18px;
  }

  .mc_textcontent .mc_btn {
    font-size: 14px;
  }

  .mc_searchBar input {
    /* width: 101px; */
    padding-right: 22px;
  }

  .mc_logo {
    width: 100%;
    max-width: 100%;
  }

  .search-dropdown {
    width: 38px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-dropdown .select__control {
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .searchIcon {
    top: 14px;
  }

  .post__content * {
    word-break: break-word !important;
    white-space: normal;
  }

}

@media(max-width:365px) {
  .mc_whiteBox_courseList {
    padding: 10px;
  }

  .mc_courdeImg {
    height: auto;
  }

  .mc_socialIcon a {
    width: 25px;
    height: 25px;
  }

  .mc_titleRow .mc_courseBtn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mc_logo img {
    max-width: 100px;
  }

  .mc_searchBar {
    width: 172px;
  }
}

























.inputTag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 5px;
  padding: 0 15px;
  padding-right: 5px;
  white-space: nowrap;
  font-size: 11px;
  color: rgb(0, 0, 0);
  background: #DFF6F1;
  border: 0.7px solid #BABABA;
  border-radius: 15px;
  width: fit-content;
  line-height: 13px;
}

.mc_tagInput_holder {
  display: flex;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 0px;
  padding: 0 20px;
  left: 0;
}

.mc_input_transperant {
  background-color: transparent;
  cursor: text;
  position: relative;
  width: 100%;
  min-width: fit-content;
  top: 0;
  line-height: 44px;
}

.inputTag button {
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  font-size: 15px;
}

.mc_eventBody .mc_tagInput_holder::-webkit-scrollbar {
  height: 4px;
}

.clickInput_holder {
  display: flex;
  align-items: center;
  justify-content: start;
}

/*********** Processing Css ******************/
.mc_waveCenter {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #000; */
}

.mc_textProcess {
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
}

.mc_waveCenter .wave {
  width: 2px;
  height: 25px;
  background: linear-gradient(45deg, rgb(0, 0, 0), #fff);
  margin: 1px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

.mc_waveCenter .wave:nth-child(2) {
  animation-delay: 0.1s;
}

.mc_waveCenter .wave:nth-child(3) {
  animation-delay: 0.2s;
}

.mc_waveCenter .wave:nth-child(4) {
  animation-delay: 0.3s;
}

.mc_waveCenter .wave:nth-child(5) {
  animation-delay: 0.4s;
}

.mc_waveCenter .wave:nth-child(6) {
  animation-delay: 0.5s;
}

.mc_waveCenter .wave:nth-child(7) {
  animation-delay: 0.6s;
}

.mc_waveCenter .wave:nth-child(8) {
  animation-delay: 0.7s;
}

.mc_waveCenter .wave:nth-child(9) {
  animation-delay: 0.8s;
}

.mc_waveCenter .wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

/* .mc_myPlanInvoice {
  margin-bottom: 120px;
} */
.mc_listMenu {
  padding: 10px;
  line-height: 24px;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  display: inline-block;
  transition: all 0.3s;
  border-radius: 0px;
  font-size: 14px;
  color: #000;
}

.mc_listMenu:hover {
  background-color: #e5e5e5;
}

.mc_switchMembership_btn .mc_btn {
  min-width: fit-content;
  width: 195px;
  border-radius: 0px;
}

.mc_switchMembership_btn ul {
  padding: 0;
  border-radius: 0px;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #cccc;
  display: none;
  max-height: 300px;
  overflow: auto;
}

.mc_switchMembership_btn ul.isOpenSwitch {
  display: block;
}

.mc_switchMembership_btn {
  position: fixed;
  right: 0;
  left: calc(100% - 40px);
  top: 90px;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  z-index: 99;
}

.mc_switchMembership_btn.slideOn {
  left: auto;
  right: 0;
  transition: all 0.5s;
}

.mc_slideDv {
  width: 45px;
  height: 45px;
  display: flex;
  min-width: 45px;
  min-height: 45px;
  align-items: center;
  justify-content: center;
  /* background-color: #ccc; */
  border-right: 1px solid rgba(255 255 255 / 25%);
  border-radius: 10px 0px 0px 10px;
}

.mainPageLoader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 88%);
  z-index: 100;
  align-items: center;
  justify-content: center;
  display: none;
  transition: all 0.3s;
}

.mainPageLoader.isopen {
  display: flex;
}

/************* Loader Css *****************/
.mc_isLoaderDesign {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.mc_isLoaderDesign:before,
.mc_isLoaderDesign:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

.mc_isLoaderDesign:before {
  transform: rotateX(70deg);
}

.mc_isLoaderDesign:after {
  transform: rotateY(70deg);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@-webkit-keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes spin {

  0%,
  100% {
    box-shadow: 0.2em 0px 1px 0px currentcolor;
  }

  12% {
    box-shadow: 0.2em 0.2em 1px 0 currentcolor;
  }

  25% {
    box-shadow: 0 0.2em 1px 0px currentcolor;
  }

  37% {
    box-shadow: -0.2em 0.2em 1px 0 currentcolor;
  }

  50% {
    box-shadow: -0.2em 0 1px 0 currentcolor;
  }

  62% {
    box-shadow: -0.2em -0.2em 1px 0 currentcolor;
  }

  75% {
    box-shadow: 0px -0.2em 1px 0 currentcolor;
  }

  87% {
    box-shadow: 0.2em -0.2em 1px 0 currentcolor;
  }
}

@keyframes spin {

  0%,
  100% {
    box-shadow: 0.2em 0px 1px 0px currentcolor;
  }

  12% {
    box-shadow: 0.2em 0.2em 1px 0 currentcolor;
  }

  25% {
    box-shadow: 0 0.2em 1px 0px currentcolor;
  }

  37% {
    box-shadow: -0.2em 0.2em 1px 0 currentcolor;
  }

  50% {
    box-shadow: -0.2em 0 1px 0 currentcolor;
  }

  62% {
    box-shadow: -0.2em -0.2em 1px 0 currentcolor;
  }

  75% {
    box-shadow: 0px -0.2em 1px 0 currentcolor;
  }

  87% {
    box-shadow: 0.2em -0.2em 1px 0 currentcolor;
  }
}


/* Chat Box Css Start */
/* *******************************
message-area
******************************** */
.searchBox_dv {
  margin-top: 15px;
  position: relative;
}

.searchBox_dv .mc_input {
  height: 45px;
  line-height: 45px;
  background-color: rgb(211 211 211 / 30%);
  padding: 0px 25px 0px 35px;
  box-shadow: none;
  border: 0;
  border-radius: 3px;
  font-size: 13px;
}

.searchBox_dv span {
  position: absolute;
  left: 10px;
  top: 17px;
}

.message-area {
  height: 100%;
  overflow: hidden;
  padding: 12px 0 0;
  /* background: #f5f5f5; */
}

.chat-list>a {
  padding: 15px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255 255 255 /10%);
}

.chat-list:last-child {
  border-bottom: 0px;
}

.chat-area {
  position: relative;
  width: 100%;
  background-color: rgb(0, 0, 0 / 10%);
  border-radius: 0;
  height: calc(100vh - 176px);
  overflow: hidden;
}

.chatlist {
  outline: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
  float: left;
  padding: 15px;
  border-right: 0;
}

.chat-area .modal-content {
  border: none;
  border-radius: 0;
  outline: 0;
  height: 100%;
  padding-left: 20px;
}

.chat-list p {
  font-size: 13px;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 60px);
}

/* .chat-area .modal-dialog-scrollable {
  height: 100% !important;
} */
.chat-area .modal-dialog-scrollable .modal-body {
  padding: 10px 10px 70px;
  height: calc(100vh - 140px);
  overflow: auto;
}

div#scroller.mc_eventListHolder.matchScreen {
  max-height: calc(100vh - 200px);
}

.chatbox {
  width: auto;
  overflow: hidden;
  /* height: 100%; */
  border-left: 1px solid rgb(255 255 255 / 10%);
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
  max-width: 100%;
  margin: 0;
}

a.add img {
  height: 36px;
}

.chat-list h3 {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
  position: relative;
}

span.mc_countUnread {
  position: absolute;
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  background-color: #22CCA6;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 0;
  color: #000;
  padding: 0px 10px;
}

.chat-list p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.chat-list a.d-flex {
  margin-bottom: 15px;
  position: relative;
  text-decoration: none;
}

.chat-list .active,
.chat-list .inactive {
  display: block;
  content: "";
  clear: both;
  position: absolute;
  bottom: 3px;
  left: 34px;
  height: 12px;
  width: 12px;
  background: #00db75;
  border-radius: 50%;
  border: 2px solid #000;
}

.chat-list .inactive {
  background-color: #fd6060;
}

.msg-head h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0;
  color: #000;
}

.msg-head p {
  color: #4A5251;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.msg-head {
  padding: 10px 0;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  position: fixed;
  background-color: #fff;
  width: 100%;
  left: 0;
  right: 0;
  padding-left: 35px;
  z-index: 100;
  top: 0;
}

.moreoption {
  display: flex;
  align-items: center;
  justify-content: end;
}

.moreoption .navbar {
  padding: 0;
}

.moreoption li .nav-link {
  color: #222;
  font-size: 16px;
}

.moreoption .dropdown-toggle::after {
  display: none;
}

.moreoption .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: auto;
  right: 0;
  margin-top: 0.125rem;
}

/* .msg-body ul {
  overflow: hidden;
} */
.msg-body ul li {
  list-style: none;
  margin: 15px 0;
}

.msg-body ul li.sender {
  display: block;
  width: 100%;
  position: relative;
}

.msg-body ul li.sender:before {
  display: block;
  clear: both;
  content: "";
  position: absolute;
  top: auto;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0px 15px 12px;
  border-color: transparent transparent rgb(255 255 255) transparent;
  transform: rotate(-25deg);
  bottom: -3px;
}

.msg-body ul li.sender p {
  background: #FFFFFF;
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  display: inline-block;
  margin-bottom: 0;
  border-radius: 7px;
  max-width: fit-content;
  width: 100% !important;
}

.msg-body ul li.sender p b {
  display: flex;
  color: #180660;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.msg-body ul li.repaly {
  display: block;
  width: 100%;
  text-align: right;
  position: relative;
}

.msg-body ul li.repaly:before {
  display: block;
  clear: both;
  content: "";
  position: absolute;
  bottom: -7px;
  right: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 14px 10px;
  border-color: transparent transparent #DCFBC4 transparent;
  transform: rotate(135deg);
}

.msg-body ul li.repaly p {
  color: #13220A;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #DCFBC4;
  display: inline-block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 0;
  max-width: fit-content;
  width: 100% !important;
}

.msg-body ul li.repaly p b {
  display: block;
  color: #061061;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.msg-body ul li.repaly:after {
  display: block;
  content: "";
  clear: both;
}

.mc_eventBody.bg_whatsApp {
  background-color: #E1D9D4 !important;
  height: 100%;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding: 20px 0px;
}

.time {
  display: block;
  color: #979595;
  font-size: 11px;
  line-height: normal;
  font-weight: 400;
  margin-top: 5px;
}

li.repaly .time {
  margin-right: 0px;
  font-size: 11px;
  color: #13220A;
}

.divider {
  position: relative;
  z-index: 1;
  text-align: center;
}

.msg-body h6 {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  background: #111827;
  display: inline-block;
  padding: 0 5px;
  margin-bottom: 0;
}

.divider:after {
  display: block;
  content: "";
  clear: both;
  position: absolute;
  top: 12px;
  left: 0;
  border-top: 1px solid rgb(255 255 255 / 10%);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.send-box {
  padding: 10px 10px;
  left: 0;
  border-top: 1px solid rgb(255 255 255 / 10%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: calc(100%);
  margin-left: 0px;
  background-color: #EDEDED;
  z-index: 999;
}

.send-box form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  width: calc(100%);
  margin-left: 10px;
}

.send-box button {
  border: none;
  background: #d5d5d5;
  padding: 7px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.send-box button i {
  margin-right: 5px;
}

.send-btns .button-wrapper {
  position: relative;
  width: 45px;
  height: 45px;
  text-align: left;
  margin: 0 auto;
  display: block;
  background: rgba(255 255 255 / 15%);
  border-radius: 50px;
  padding: 0px;
  float: left;
  margin-right: 5px;
  margin-bottom: 0;
  overflow: hidden;
  line-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.send-btns .button-wrapper svg {
  margin-right: 0px;
}

.send-box input.mc_input {
  padding: 0px 75px 0px 20px;
  height: 45px;
  line-height: 45px;
  background-color: #EDEDED;
  border: 0;
  border-radius: 50px;
  box-shadow: none;
}

.send-box .input_holder .mc_input {
  max-height: 85px;
  line-height: 20px;
  font-size: 14px;
  width: 100%;
  background-color: #fff;
  border: 0px;
  border-radius: 50px;
  padding: 13px 75px 0 20px;
  height: 45px;
}

.send-btns .button-wrapper span.label {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: #343945;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 13px;
  text-align: center;
  justify-content: center;
}

#upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.send-btns .attach .form-control {
  display: inline-block;
  width: 120px;
  height: auto;
  padding: 5px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #343945;
  background-color: #f6f7fa;
  background-clip: padding-box;
  border: 1px solid #f6f7fa;
  border-radius: 3px;
  margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
  margin-right: 5px;
}

.button-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
}

.add-apoint {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-apoint a {
  text-decoration: none;
  background: #f6f7fa;
  border-radius: 8px;
  padding: 0px 8px;
  font-size: 13px;
  font-weight: 400;
  color: #343945;
  width: 45px;
  height: 45px;
  line-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-apoint a svg {
  margin-right: 0;
}

.chat-icon {
  display: none;
  position: relative;
  z-index: 99;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 10px;
}

.send-btns .attach {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closess i {
  display: none;
}

.flex-shrink-0 {
  position: relative;
}

/******************/
.msg-body ul li.repaly p img {
  max-width: 200px;
  max-height: 150px;
}

.input_holder span {
  position: absolute;
  right: 45px;
  top: 7px;
  cursor: pointer;
}

.input_holder {
  position: relative;
  width: 100%;
}

.ms-3 {
  margin-inline-start: 0.75rem;
}

.mc_holdContent {
  max-width: calc(100% - 120px);
}

.msg-body ul li.repaly p .sendTick {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.sendTick>svg {
  fill: #8FAB7A;
}

.sendTick.doubleTick>svg {
  fill: #0DA9E5;
}

.msg-body ul li.repaly>p {
  padding-right: 30px !important;
  position: relative;
}

.mc_eventBody.bg_whatsApp .chat-area.mc_attendiesChat_list {
  padding-top: 50px;
  height: calc(100vh - 120px);
}

.mc_eventBody.bg_whatsApp .chat-area.mc_attendee_wraper {
  height: calc(100vh - 110px);
}

.mc_eventBody.bg_whatsApp .chat-area.mc_attendiesChat_list .modal-dialog-scrollable {
  padding-top: 0;
}

.mc_eventBody.bg_whatsApp .chat-area.mc_attendiesChat_list .modal-dialog-scrollable .modal-body {
  padding-top: 0px;
}

/***************** Event Profile Page ******************/
.mc_profileIcon_show .mc_profileHead_img svg {
  width: 45px;
  height: 45px;
}

.mc_eventBody.mc_profileScroller {
  height: auto;
  min-height: auto;
  width: 100%;
  padding: 0px 0px;
  top: -65px;
  position: relative;
}

.mc_eventProfileForm .mc_input {
  padding: 0 20px;
  line-height: 43px;
  margin-bottom: 15px;
  color: #000;
  font-size: 12px;
  box-shadow: none;
  background: #FFFFFF;
  border: 0.6px solid #DFDFDF;
  border-radius: 4px;
  height: 43px;
}

.mc_formGroup .uploadIcom {
  font-size: 15px;
  color: #000;
}

.mc_profilePic_holder {
  width: fit-content;
  margin-right: 30px;
}

.mc_picUrl {
  display: flex;
  align-items: start;
  justify-content: center;
}

.mc_picUrl .mc_profilePic_holder img,
.mc_alfaProfile_img {
  width: 85px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin: 0 auto 10px;
  border-radius: 100%;
}

.mc_manageContent h3 span.mc_matchIcon {
  padding: 0px 7px;
  line-height: 20px;
  display: inline-block;
  background-color: #22CCA6;
  margin-bottom: 7px;
  border-radius: 5px;
  color: #000;
  font-weight: 500;
  width: fit-content !important;
  min-width: fit-content !important;
  margin-left: 3px !important;
}

/* .mc_footItems.mc_matchIcon svg {
  width: 35px;
  height: 35px;
} */
.mc_picUrl img {
  width: 150px;
}

.hasDropdown .mc_profile_dropdown {
  position: absolute;
  flex-wrap: wrap;
  width: 160px;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  display: none;
  z-index: 99;
  box-shadow: 0 0 10px rgba(0 0 0 / 10%);
}

.hasDropdown {
  position: relative;
}

.mc_profile_dropdown li {
  width: 100%;
  border-bottom: 1px solid rgba(0 0 0 / 10%);
}

.mc_eventHeaderMenu ul .mc_profile_dropdown a {
  width: 100%;
  background-color: transparent;
  margin: 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 3px 20px;
  font-size: 13px;
}

.hasDropdown .mc_profile_dropdown.showProfile {
  display: block;
}

.mc_backBtn_dv {
  z-index: 101;
  position: fixed;
}

.mc_backBtn_dv>a#gotoBack_dv {
  position: fixed;
  top: 29px;
  left: 10px;
  z-index: 88;
}

.mc_backBtn_dv>a {
  display: flex;
  align-items: center;
}

.mc_eventListHolder .mc_congrats_dv {
  max-height: calc(100vh - 190px);
}

.mc_eventListHolder .chatlist {
  padding: 10px 0;
}

.mc_eventListHolder .chat-list>a {
  background-image: linear-gradient(to right, #D6D6D6 33%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 5px 2px;
  background-repeat: repeat-x;
  padding: 15px 0;
}

.mc_eventListHolder .chat-list:first-child>a {
  background-image: linear-gradient(to right, #D6D6D6 33%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 5px 2px;
  background-repeat: repeat-x;
}

.mc_chatBtn_box {
  display: flex;
  margin-right: 5px;
}

.mc_chatBtn_box svg {
  margin-right: 5px;
}

.msg-body ul li.mc_chatLoader {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E1D9D4;
  color: #22cca6;
  top: 0;
  z-index: 99;
}

.mc_loadMore_fv {
  animation: rotating 2s linear infinite;
  width: 25px;
  height: 25px;
  border: 2px dotted #22cca6;
  margin: 11px;
  border-radius: 27px;
}

.isOnline_user {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #22cca6;
  position: absolute;
  right: 0;
  top: 0px;
  display: none;
}

.isActiveUser .isOnline_user {
  display: block;
}

.mc_componentData.mc_userProfile_head {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 100;
}

.mc_userProfile_head .mc_userProfileImage_holder {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 25px 20px 10px;
  width: 100%;
  text-align: center;
  position: relative;
  flex-wrap: wrap;
}

.mc_userProfile_head .mc_headTitle_dv {
  height: 120px;
  background-color: #D5F8F0;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 11;
  top: 0;
  align-items: start;
  padding: 25px 20px;
}

.mc_userProfile_head .mc_headTitle_dv h2 {
  width: 100%;
  padding-left: 12px;
}

.mc_userProfile_head .mc_profilePic_holder {
  position: relative;
  z-index: 12;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

.mc_userProfile_head .mc_profileSection {
  height: auto;
  width: 100%;
  max-height: calc(100vh - 245px);
}

.mc_userProfile_head .mc_alfaProfile_img {
  border: 5px solid #fff;
}

.mc_headTitle_dv.padd_none {
  padding: 0px;
}

div#scroller.mc_eventListHolder.mc_scrollBar_inner {
  max-height: calc(100vh - 170px);
  overflow: auto;
}

.mc_userProfile_head .mc_profileContent {
  font-size: 13px;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px #00000029;
  border-radius: 4px;
  padding: 20px;
  max-width: 450px;
  width: 100%;
  max-height: max-content;
  height: auto;
}

.mc_userProfile_head .mc_manageContent {
  width: 100%;
}

.mc_QrCode_img {
  width: 100%;
  max-width: 100%;
}

.mc_userProfile_head .mc_manageContent h3 {
  flex-wrap: wrap;
  padding-bottom: 15px;
  border-bottom: 1px dotted;
  margin-bottom: 20px;
  flex-direction: column;
  white-space: normal;
  word-break: normal;
}

.mc_userProfile_head .mc_manageContent h3 .inputTag {
  padding: 0px 15px;
  line-height: 20px;
}

.mc_userProfile_head .mc_manageContent h3 span {
  margin-left: 0px;
  font-size: 13px;
}

.mc_userProfile_head .mc_manageContent h3 span:first-child {
  min-width: 120px;
  width: 100%;
  display: block;
  margin-left: 0;
  font-weight: bold;
  color: #000;
}

.mc_userProfile_head .mc_manageContent h3 span.mc_matchIcon {
  padding: 0px 0px;
  line-height: 20px;
  display: inline-block;
  background-color: transparent;
  margin-bottom: 7px;
  border-radius: 0px;
  color: #000;
  font-weight: 500;
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  margin-left: 5px !important;
}

.mc_updateprofile_head {
  padding: 10px 35px;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  position: fixed;
  background-color: #fff;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  top: 0;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

form.mc_eventForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

form.mc_eventForm .mc_eventProfileForm {
  position: relative;
  left: 0;
  right: 0;
  padding: 0px 0px;
  bottom: 0;
  background-color: transparent;
  z-index: 8;
  padding-top: 20px;
  max-height: calc(100vh - 140px);
  overflow: auto;
  padding-bottom: 0px;
  top: 0px;
}

.mc_homePage {
  position: absolute;
  left: 10px;
}

.mc_scrollBar_inner .chat-list .mc_alfaProfile_img {
  margin-right: 0;
}

.chat-area.mc_attendee_wraper {
  height: calc(100vh - 200px);
}

/**************** Typing... Doted Css Effect **************/
.chat-bubble {
  /* background-color: #e6f8f1; */
  padding: 16px 28px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6cad96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: rgba(20, 105, 69, .7);
  }

  28% {
    transform: translateY(-7px);
    background-color: rgba(20, 105, 69, .4);
  }

  44% {
    transform: translateY(0px);
    background-color: rgba(20, 105, 69, .2);
  }
}

/*************************************/
/****** Profile Details Pages Css *********/
.mc_profilePic {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.mc_picUrl {
  width: 100%;
  border-bottom: 1px solid rgba(163, 163, 163, 0.1);
  border-radius: 0px;
  text-align: center;
  padding-bottom: 20px;
}

.mc_picUrl img {
  border-radius: 10px;
  margin: 0px;
  /* border: 1px solid; */
}

.mc_profileContent {
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.mc_profileContent ul li {
  margin-bottom: 10px;
  list-style: square;
}

.mc_manageContent h3 {
  display: flex;
  align-items: start;
  /* justify-content: space-between; */
}

.mc_manageContent h3 span {
  margin-left: 20px;
  font-size: 13px;
}

.mc_manageContent p {
  font-size: 13px;
}

.mc_manageContent h3 span:first-child {
  min-width: 120px;
  width: 120px;
  margin-left: 0;
}

.mc_profileContent {
  font-size: 13px;
}

.mc_eventBody *::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  visibility: hidden;
}

.mc_eventBody *::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #f3f3f3;
  visibility: hidden;
}

.mc_eventBody *::-webkit-scrollbar-thumb {
  background-color: #22CCA6;
  outline: none;
  border-radius: 10px;
  visibility: hidden;
}

.mc_eventHeaderMenu h3 span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.555);
}

.mc_eventHeaderMenu h3 {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  line-height: 16px;
}

.mc_copyText {
  display: flex;
  align-items: center;

}

.mc_copyText>a {
  margin-left: 10px;
}

.mc_copyUrl_holder>span>a svg {
  width: 15px;
  height: 15px;
}

.mc_userProfile_head .mc_manageContent h3.mc_copyUrl_holder>span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mc_copyUrl_holder spam.mc_copyText {
  white-space: normal;
  word-break: break-all;
}

.mainPageLoader.eventLoader {
  position: fixed;
}

.mc_mainLoader_dv {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: auto;
}

/********* Notification Css ************/
.mc_pushNofification .mc_formGroup .mc_input {
  height: 45px;
  line-height: 45px;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: none;
}

.mc_pushNofification .mc_formGroup .mc_textarea {
  height: auto;
  line-height: normal;
  padding: 10px 20px;
}

.mc_pushNofification .mc_formGroup .uploadIcom {
  font-size: 13px;
}

.mc_notification_wrapper .mc_formGroup .uploadProfile_Label {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: none;
}

.mc_notification_wrapper {
  padding: 10px 0px;
}

.mc_pushCount {
  position: relative;
}

.mc_msgCount {
  position: absolute;
  font-size: 11px;
  width: 18px;
  height: 18px;
  background-color: #37ffcd;
  border-radius: 20px;
  right: 4px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.mc_videoDv .video-js,
.mc_videoDv .vjs-tech,
.mc_videoDv iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
}

INPUT:not(:-webkit-autofill),
SELECT:not(:-webkit-autofill),
TEXTAREA:not(:-webkit-autofill) {
  color: #000;
}

.mc_eventForm .mc_formGroup .uploadProfile_Label {
  width: 100%;
  border-radius: 5px;
  line-height: 43px;
  height: 43px;
  padding: 0 20px;
  font-size: 13px;
  background: #FFFFFF;
  border: 0.6px solid #DFDFDF;
  box-shadow: none;
}

.mc_eventForm .mc_formGroup .uploadProfile_Label .uploadIcom {
  font-size: 12px;
  color: #000;
  font-weight: normal;
}

.mc_chatContent_holder {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: calc(100% - 60px);
}

.mc_ChatIcon_list {
  margin-left: 10px;
  position: relative;
}

.mc_ChatIcon_list svg {
  fill: gray;
}

.mc_ChatIcon_list.offline_user svg {
  fill: #D1D1D1;
}

.mc_chatUser_content {
  width: calc(100% - 40px);
}

.mc_agendaCOntemt h6 {
  font-size: 13px;
  margin-bottom: 10px;
  color: #000;
}

.mc_agendaCOntemt {
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px dotted #E2E2E2;
}

.mc_daysList {
  background: rgb(0 0 0 / 3%);
  border-radius: 4px;
  margin: 20px 0 10px;
  padding: 1px;
}

.mc_daysList ul {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mc_daysList ul li {
  width: 100%;
  text-align: center;
  margin: 5px;
  line-height: 31px;
  font-size: 13px;
  background: transparent;
  box-shadow: none;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 500;
}

.mc_daysList ul li.isActive {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #84DBC8;
}

.mc_userProfile_head .p-image {
  position: relative;
}

.mc_userProfile_head .profile-pic {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 41px;
  height: 41px;
  background-size: cover;
  border-radius: 0 4px 4px 0;
  background-position: center;
}

.mc_titleCount {
  font-weight: 600;
}

.mc_speakerList_dv.mc_speakerList_scroller {
  max-height: calc(100vh - 200px) !important;
}

.modal-dialog-scrollable {
  padding-top: 25px;
}

.mc_validationCode_wrapper {
  height: calc(100vh - 140px);
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  top: 0;
}

.mc_notifyCode_dv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.mc_headeing_dv {
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
}

.mc_notifyCode_dv p {
  text-align: center;
  font-size: 13px;
  margin-bottom: 20px;
}

.mc_notifyCode_dv .mc_btn {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

/************** Event Home Page Css **************/
.mc_superTag {
  font-size: 10px;
  font-weight: normal;
  display: block;
}


.mc_pageIconCircle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 15px;
}

.mc_pinkIcon {
  background-color: #EC1E51;
}

.mc_bluelIcon {
  background-color: #7147D1;
}

.mc_yellowIcon {
  background-color: #FAA610;
}

.mc_pageIconCircle svg {
  fill: #fff;
  stroke: #fff;
}

.mc_listIcon_page {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  line-height: 35px;
  margin-bottom: 10px;
}

.mc_pageName_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 55px);
}

li:last-child .mc_listIcon_page {
  margin-bottom: 0px;
}

.mc_pageMenuList ul {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccc;
}

.mc_bottomContent .mc_calangerList svg path {
  fill: #b3b2b2;
}

.mc_bottomContent .mc_calangerList svg {
  width: 20px;
  height: 20px;
  fill: #b3b2b2;
  position: absolute;
  left: 0;
  top: 0;
}

.mc_bottomContent .mc_calangerList {
  position: relative;
  padding-left: 30px;
  align-items: center;
  line-height: normal;
  margin-top: 10px;
}

.mc_homeWrapper {
  height: calc(100vh - 140px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mc_hidden {
  display: none !important;
}

.mc_eventListHolder.mc_agendaList {
  max-height: calc(100vh - 265px);
}

.mc_speakerBg {
  background-color: #fff !important;
  /* height: calc(100vh - 65px);
  overflow: auto; */
}

.mc_manageList_content .mc_alfaProfile_img {
  margin: 0 10px 0px 0px;
  width: 50px;
  height: 50px;
}

.mc_alfaProfile_img svg {
  width: 50px;
  height: 50px;
}

.chat-list .mc_alfaProfile_img {
  height: 50px;
  width: 50px;
  margin-bottom: 0;
}

.mc_profileBg_img {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  min-width: 50px;
}

.mc_alfaProfile_img {
  width: 40px;
  height: 41px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 10px 0px 0;
  border-radius: 100%;
}

#mainHeaderMenu.hidden {
  display: none !important;
}

.mc_profileScroller .mainPageLoader.isopen {
  position: fixed;
  z-index: 9999;
}

.donnotmessage {
  font-size: 12px;
  max-width: 100%;
  margin: 5px auto 0;
  position: fixed;
  color: #000;
  z-index: 99999;
  bottom: 10px;
  background-color: #f3f3f3;
  padding: 0px 5px;
}

.mc_profilePic_holder .mc_alfaProfile_img {
  width: 85px;
  height: 85px;
  margin: auto;
}

.mc_profilePic_holder .mc_alfaProfile_img svg {
  width: 75px;
  height: 75px;
}

sup.require-star {
  zoom: 1.5;
  color: red;
  top: 0px;
  left: 0px;
}

.mc_userProfile_head .mc_manageContent h3 .inputTag {
  padding: 0px 15px;
  line-height: 20px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}

.chat-area.mc_chatmsg_box {
  border-radius: 0;
  height: calc(100vh - 120px);
}

.mc_completeModal div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mc_CommentReply_outer,
.mc_CommentReply {
  height: auto !important;
  overflow: visible;
}

.mc_CommentReply_outer.hidden,
.mc_CommentReply.hidden {
  height: 0px !important;
}

.mc_confermationModal .mc_modalDialog {
  max-width: 450px !important;
}

.mc_confermationModal .mc_moadlImage {
  width: 130px;
}

.mc_confermationModal .mc_textContentModal {
  width: calc(100% - 130px);
  padding-left: 15px;
}

.mc_confermationModal .mc_btn {
  height: 38px;
  border-radius: 5px;
  line-height: 38px;
}

.mc_userDetails_dv .mc_userName .mc_likeReply a {
  padding-right: 20px;
}

.mc_font20 {
  font-size: 20px;
}


/* .vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-hidden {
  display: block !important;
} */

/******************** * Select 2 Css Start * ******************/

.rz_customSelect div[class*=-placeholder],
.rz_customSelect div[class*=-singleValue] {
  font-size: 14px;
  font-weight: 300;
  color: var(--placeholder_color);
}

.rz_customSelect div[class*=-singleValue] {
  font-size: 14px;
  font-weight: 400;
  color: var(--headdingColor);
}

.rz_customSelect div[class*=--indicatorSeparator] {
  font-size: 18px !important;
}

.rz_customSelect div[class*="-MenuList"] {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 14px;
  color: var(--textColor);
}

.rz_customSelect div[class*="-MenuList"],
.rz_customSelect div[class*="-option"] {
  cursor: pointer !important;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  text-transform: none !important;
}

.rz_customSelect [class*='-control'] {
  color: var(--textColor);
  border-radius: 5px;
  font-weight: 400;
  min-width: 220px;
  height: auto;
  line-height: 50px;
  font-size: 14px;
  background: var(--whiteColor);
  padding: 0 20px;
  min-height: 50px;
  cursor: pointer;
}

.rz_customSelect [class*='-ValueContainer'],
.rz_customSelect [class*='-Input'] {
  padding: 0;
  margin: 0;
}

.rz_customSelect span[class*="-indicatorSeparator"] {
  display: none;
}

.rz_customSelect div[class*="-menu"] {
  z-index: 90;
}

.rz_customSelect div[class*="-option"] {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 35px;
}

.rz_customSelect .option,
.rz_customSelect div[class*="-indicatorContainer"] {
  padding: 0;
}

.rz_customSelect div[class*='-ValueContainer'],
.rz_customSelect div[class*='-multiValue']>div {
  line-height: 28px !important;
  padding: 0px 5px 0px 10px !important;
  background-color: rgb(0 0 0 / 0%);
  color: var(--whiteColor);
  border-radius: 0px;
}

.rz_customSelect div[class*='-multiValue'] {
  background-color: var(--primaryColor) !important;
  border-radius: 3px !important;
  margin: 2px !important;
  display: inline-flex;
  padding: 0 1px;
  text-align: center;
  justify-content: space-between;
}

.rz_customSelect div[class*='-ValueContainer'] {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0px !important;
}

.rz_customSelect [class*='-IndicatorsContainer'] {
  position: relative;
  left: 0px;
}

.rz_customSelect [class*='-control'] {
  border: 1px solid var(--borderColor);
  background-color: var(--whiteColor);
  box-shadow: none;
}

.rz_customSelect [class*='-control']:hover {
  border-color: var(--primaryColor);
}

.rz_customSelect [class*='-Input']>input {
  height: 48px;
}

.rz_customSelect [class*='-indicatorSeparator'] {
  background-color: var(--primaryColor);
  color: rgb(0, 0, 0);
}

.rz_customSelect div[class*='-multiValue']>div[role="button"] {
  padding-left: 4px !important;
  border-left: 1px solid rgb(255 230 184);
}

.rz_customSelect div[class*='-ValueContainer']::-webkit-scrollbar {
  height: 3px;
  box-shadow: none;
}

.rz_customSelect div[class*='-ValueContainer']::-webkit-scrollbar-track {
  background-color: rgb(230 238 246);
  box-shadow: none;
}

.rz_customSelect div[class*='-ValueContainer']::-webkit-scrollbar-thumb {
  background-color: var(--primaryColor);
  box-shadow: none;
  border-radius: 10px;
}



/******************** * Select 2 Css End * ******************/
.mc_eventList_Loader svg.mc_eventScaleLoader_ {
  margin-bottom: -3px !important;
}

.mc_eventList_Loader {
  position: relative;
  width: 100%;
}

/*********************************** Discussion Screen ******************************************/
.mc_userDetails_dv img {
  width: 50px;
  height: 50px;
}

.comment-input-box .mc_userDetails_dv img {
  width: 36px;
  height: 36px;
}

.comment-input-box .mc_commentGrayBox_dv .mc_commentBox_text {
  width: calc(100% - 50px);
}

.comment-input-box .mc_commentGrayBox_dv {
  padding: 20px;
  background: #F4F4F4;
}

.mc_discussionReply .mc_grayBox_reply.mc_boxInnerComment::before {
  left: 105px;
}

.mc_discussionReply .mc_discussionReply .mc_grayBox_reply.mc_boxInnerComment::before {
  left: 60px;
}

.mc_fixedLoader {
  position: fixed;
  width: calc(100% - 40px);
  z-index: 9;
  bottom: 80px;
  left: 0;
  right: 0;
  margin: auto;
  top: 285px;
  height: calc(100vh - 150px);
  background-color: #f7f7f7;
}

.mc_discussionReply .mc_commentGrayBox_dv.mc_innerChildren {
  padding: 0px 25px;
}

.mc_moadlImage>img {
  width: 100%;
  height: 170px;
}


/* Select search  */

.search-dropdown .select__control {
  border-width: 0px;
  box-sizing: border-box;
  height: 38px;
  background: var(--themeColor2);
  color: var(--theme_textColor_btn);
  border-radius: 999px;
  /* margin: 1px; */
}

.search-dropdown .select__control:hover {
  border: none;
  border-color: transparent !important;
}

.search-dropdown .select__placeholder {
  color: var(--theme_textColor_btn);
  font-size: 15px;
}

.search-dropdown .select__indicator svg path {
  fill: var(--theme_textColor_btn);
}

.search-dropdown .select__option {
  font-size: 14px;
  padding: 8px 9px;
}

.search-dropdown .select__indicator-separator {
  width: 0;
  display: none;
}

.search-dropdown .select__single-value {
  font-size: 14px;
  color: var(--theme_textColor_btn);
  /* padding-top: 5px; */
}

.search-dropdown .select__indicators {
  height: 38px;
}

.search-dropdown .select__value-container,
.search-dropdown .select__input-container {
  height: 38px;
  margin: 0;
  padding: 2px 0px 2px 8px;
}

.search-dropdown .select__menu {
  background: #f7f7f7;
  color: unset;
  width: 125px;
}

.search-dropdown .select__option {
  cursor: pointer;
}

.search-dropdown .select__option--is-selected {
  color: #000;
  background-color: #dbdada;
}

.search-dropdown .select__option--is-focused {
  background-color: #eeeaea;
}

.search-dropdown .select__option--is-focused:active {
  background-color: #dbdada;
}

.mc_dropdown_lesson::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.mc_dropdown_lesson::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.mc_dropdown_lesson::-webkit-scrollbar-thumb {
  background: var(--themeColor2);
}

.mc_searchBar {
  position: relative;
}

.mc_dropdown_lesson {
  position: absolute;
  top: 45px;
  width: 100%;
  background-color: #fff;
  max-height: 300px;
  overflow: auto;
}

.mc_dropdown_lesson ul li {
  border-bottom: 0.5px solid #f1f1f1;
}

.mc_dropdown_lesson ul li a {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .mc_overlayDv {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 45px;
  z-index: 9999;
  width: 100%;
  height: calc(100% - 45px);
} */

.mc_vidoBox_fixed .mc_videoDv {
  min-height: max-content;
}

.minHeight_tabContent {
  min-height: 350px;
}

/*************** Goals Modal ****************/
.mc_goalModalCoverImage {
  background-position: center;
  background-size: cover;
  height: 150px;
  margin-bottom: 70px;
}

.mc_goalModalBannerImage {
  position: absolute;
  left: 0;
  right: 0;
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  margin: 100px 0px 0px 70px;
}

/************************************/
/* Disable youtube options start*/
.html5-video-player:not(.ytp-big-mode) .ytp-chrome-top .ytp-watch-later-button {
  display: none !important;
}

.html5-video-player:not(.ytp-big-mode) .ytp-chrome-top .ytp-share-button {
  display: none !important;
}

.ytp-overflow-icon {
  display: none !important;
}

.App .ytp-chrome-top-buttons .ytp-button {
  display: none !important;
}

/* Disable youtube options end*/
@media (max-width: 1280px) {
  .search-dropdown {
    width: 38px;
  }

  .search-dropdown .select__indicator {
    padding: 5px 4px 0 0;
  }

  .search-dropdown .select__indicator svg {
    width: 15px;
  }

  .search-dropdown .select__value-container,
  .search-dropdown .select__input-container {
    padding: 2px 0px 2px 4px;
    display: none;
  }

  .search-dropdown .select__single-value {
    font-size: 13px;
  }

  .mc_searchBar {
    width: 360px;
  }

  .mc_searchBar input {
    width: 137px;
    padding-left: 0;
    padding-right: 45px;
  }

  .search-dropdown .select__indicators {
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .chat-icon {
    display: flex;
    margin-right: 5px;
  }

  .chatlist {
    width: 100%;
  }

  .mobileHead {
    display: none;
  }

  .chatbox {
    width: 100%;
    position: absolute;
    left: 1000px;
    right: 0;
    /* background: #fff; */
    transition: all 0.5s ease;
    border-left: none;
  }

  .showbox {
    left: 0 !important;
    transition: all 0.5s ease;
  }

  .msg-head h3 {
    font-size: 14px;
  }

  .msg-head p {
    font-size: 12px;
  }

  .msg-head .flex-shrink-0 img {
    height: 30px;
  }

  /* .send-box button {
    width: 28%;
  } */
  .send-box .form-control {
    width: 70%;
  }

  .chat-list h3 {
    font-size: 14px;
  }

  .chat-list p {
    font-size: 12px;
  }

  .msg-body ul li.sender p {
    font-size: 13px;
    padding: 8px;
  }

  .msg-body ul li.repaly p {
    font-size: 13px;
    padding: 8px 35px 8px 8px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .showUserList {
    position: fixed;
    align-items: center;
    top: 0;
    padding: 45px 15px 15px 55px;
    z-index: 99;
    height: 100vh;
    background-color: #000;
    max-width: 300px;
    left: -100%;
    transition: all 0.5s;
  }

  .showUserList.mobileHead {
    display: block;
    left: 0;
  }

  .mc_profileContent {
    max-width: calc(100% - 120px);
  }

  .mc_picUrl {
    width: 100%;
    border-radius: 10px;
    text-align: center;
  }

  .mc_assessmentContent img {
    width: 240px;
  }

  .mc_profileImg_bg {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
  }

  .pointer_all iframe#vimeo-video_youtube_api,
  .pointer_all div#vimeo-video_vimeo_api iframe {
    pointer-events: all !important;
  }

  .pointer_none iframe#vimeo-video_youtube_api,
  .pointer_none div#vimeo-video_vimeo_api iframe {
    pointer-events: none !important;
  }

  .mc_commentGrayBox_dv {
    padding: 15px;
  }

  .mc_discussionReply .mc_grayBox_reply.mc_boxInnerComment {
    padding: 0 0 0 15px;
  }

  .comment-input-box .mc_commentGrayBox_dv {
    padding: 10px;
  }

  .mc_discussionReply .mc_grayBox_reply.mc_boxInnerComment .mc_grayBox_reply.mc_boxInnerComment {
    padding: 0px 0 0 0px;
  }

  .mc_searchBar {
    width: 220px;
  }

  .mc_searchBar input {
    width: 162px;
    padding-left: 0;
    padding-right: 25px;
  }
}

@media(max-width:450px) {
  .mc_profileContent {
    max-width: calc(100% - 20px);
  }

  .mc_likeReply a {
    padding-right: 15px;
    font-size: 12px;
  }

  .mc_likeReply a span.mr-2 {
    margin-right: 5px;
  }

  .mc_userDetails_dv img {
    width: 30px;
    height: 30px;
  }

  .comment-input-box .mc_userDetails_dv img {
    width: 25px;
    height: 25px;
  }

  .comment-input-box .mc_commentGrayBox_dv .mc_commentBox_text {
    width: calc(100% - 37px);
  }

  .mc_commentBox_text {
    width: calc(100% - 42px);
  }

  .mc_flexbox_left .mc_moboilCol_12>p {
    font-size: 12px;
    margin-right: 15px;
  }

  .mc_modalRewardContent .mc_textContentModal {
    width: 100%;
    margin-top: 5px;
  }

  .mc_flexReward {
    flex-direction: column;
  }

  .mc_rewardBatch_box {
    max-width: 100%;
  }

  .mc_rewardBatch_box:last-child {
    margin-top: 10px;
    margin-left: 0;
  }

  .mc_modalRewardContent {
    padding: 10px;
  }

  .mc_modalRewardContent .mc_moadlImage {
    width: 180px;
    height: 172px;
  }

  .mc_modalRewardContent .mc_textContentModal {
    padding-left: 0;
    padding-right: 0;
  }

  .search-dropdown .select__indicator {
    padding: 0px;
  }

  .search-dropdown .select__indicator svg {
    width: 15px;
  }

  .search-dropdown .select__value-container,
  .search-dropdown .select__input-container {
    padding: 2px 0px 2px 4px;
    display: none;
  }

  .search-dropdown .select__single-value {
    font-size: 13px;
  }

  .mc_searchBar {
    width: 180px;
  }

  .mc_searchBar input {
    width: 141px;
    padding-left: 0;
    padding-right: 25px;
  }

  .mc_dropdown_lesson ul li a {
    max-height: 35px;
  }
}

@media(max-width:360px) {
  .mc_searchBar {
    width: 165px;
  }

  .searchIcon {
    margin-right: 5px;
  }
}

.post__content.unset_listStyle {
  padding: 0 20px;
}

/* src/Draggable.css */
.draggable {
  position: fixed;
  z-index: 9999;
  cursor: grabbing;
}

.draggable:active {
  cursor: grabbing;
}

.mc_spinnerLoader {
  position: absolute;
  left: 0;
  right: 0;
  width: 28px;
  border: 0;
  height: 28px;
  margin: auto;
  border-width: 4px;
  border-bottom: 1px solid;
}

.mc_videoComponent .spinnerLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.mc_videoComponent .spinnerLoader div#myViemoIframe {
  width: 100%;
}

div#containerBody.d-none {
  display: none;
}

div#containerBody.d-block {
  display: block;
}

body.video_fullscreen .mc_headerWrapper,
body.video_fullscreen .mc_chevronsList,
body.video_fullscreen .footerDv_fix {
  display: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}

.video-js.vjs-controls-enabled .vjs-big-play-button {
  display: none;
}

.video-js.vjs-controls-enabled.vjs-paused .vjs-big-play-button {
  display: block;
}